<div class="dropdown custom-right-dropdown dropright">
  <button type="button" (click)="onAddVariablesButtonClick(variablesList)" aria-haspopup="true"
          aria-expanded="false" class="btn btn-default variables-button ml-1"
          id="emailTitleDropDown"
          [disabled]="isDisabled"
          data-toggle="dropdown">
    {{!hideText ? 'Variables' : ''}}
  </button>

  <ng-template #variablesList let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-title">Variables</h4>
      <button
        type="button"
        class="close"
        aria-label="Close button"
        aria-describedby="modal-title"
        (click)="modal.dismiss('cancel')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <iframe #iframe class="widget-integration-frame"
              frameborder="0" width="100%" height="600px"
              *ngIf="variableListUrl"
              [src]="variableListUrl">
      </iframe>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="modal.close()">Close</button>
    </div>
  </ng-template>
</div>
