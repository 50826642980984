<email-template-editor
  *ngIf="showEditor['email-builder-js']"
  [template]="templateData"
  [variables]="variables"
  (EditorStateChanged)="onEditorStateChanged($event, 'email-builder-js')"></email-template-editor>
<div class="container-fluid pt-3 mobile-p0 campaign-creation-steps">
  <div class="rightBody">
    <form [formGroup]="templateForm" class="py-3" [style.margin-left]="f.template_type_id?.value == 2 ? '' : '19rem'">
      <div class="card">
        <div class="card-body">
          <div class="form-group">
            <div class="row">
              <div class="col-md-3">
                <div class="d-flex align-items-center">
                  <small>Template Name <span class="text-danger">*</span></small>
                </div>
                <div class='input-group input-group-sm'>
                  <input class="form-control" placeholder="Template name" type="text"
                         [ngClass]="{ 'is-invalid': submitted && f.name.errors }" formControlName="name">
                </div>
                <div class="bar error-brd" *ngIf="submitted && f.name.errors">
                  <div class="invalid-text" *ngIf="f.name.errors.required">Template Name is required</div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="d-flex align-items-center">
                  <small>Influencer Type</small>
                </div>
                <div class='input-group input-group-sm'>
                  <select class="floating-select" formControlName="influencer_type_id"
                          [ngClass]="{ 'is-invalid': submitted}">
                    <option [value]='influencerType.id'
                            *ngFor="let influencerType of influencerTypes">{{influencerType.name}}</option>
                  </select>
                </div>
<!--                <div class="bar error-brd" *ngIf="submitted && f.influencer_type_id.errors">-->
<!--                  <div class="invalid-text" *ngIf="f.influencer_type_id.errors.required">Influencer type is required-->
<!--                  </div>-->
<!--                </div>-->
              </div>
              <div class="col-md-3">
                <div class="d-flex align-items-center">
                  <small>Template Type <span class="text-danger">*</span></small>
                </div>
                <div class='input-group input-group-sm'>
                  <select class="floating-select" formControlName="template_type_id"
                          [ngClass]="{ 'is-invalid': submitted && f.template_type_id.errors }">
                    <option class="default-option"  [value]="null" [selected]="true" [disabled]="true">Select Template Type</option>
                    <option [value]='templateType.id'
                            *ngFor="let templateType of templateTypes">{{templateType.name}}</option>
                  </select>
                </div>
                <div class="bar error-brd" *ngIf="submitted && f.template_type_id.errors">
                  <div class="invalid-text" *ngIf="f.template_type_id.errors.required">Template type is required
                  </div>
                </div>
              </div>
              <div class="col-md-3 pt-3">
                <input formControlName="visibility" placeholder="" type="checkbox" class='mr-2' (change)="onChangeVisibility($event)">
                <label>Template Visibility</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <app-template-editor
      *ngIf="f.template_type_id?.value != 2"
      [template]="template" [viewportSize]="viewportSize"
      (OnTemplateLoaded)="templateLoaded($event)"
      (OnPageSettingsChanged)="onStylesCustomizationChange($event)"
      #templateEditor [pageSetting]="stylesForm.value" [variables]="variables"
      (OnTemplateTypeChange)="fetchRawTemplatesById($event)" [showBlockSelector]="true" [showTemplateSelector]="false"
      [rawTemplatesTypes]="rawTemplatesTypes" [templates]="templates"></app-template-editor>
    <email-template-preview
      *ngIf="f.template_type_id?.value == 2 && editorInfo.name == 'email-builder-js'"
      [template]="templateData"></email-template-preview>
  </div>
</div>
<div class="operation-box">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-4">
        <div class="text-left">
          <button class="btn btn-default mr-2" (click)="onBackButtonClick()" type="button">
            Back
          </button>
          <button [disabled]="!isTemplateLoaded" *ngIf="templateId"
                  class="btn btn-default mr-2"
                  (click)="showTemplateRevisions()">
            Undo All
          </button>
          <button *ngIf="f.template_type_id?.value == 2"
                  class="btn btn-default"
                  (click)="showTemplateEditor()">
            {{!this.template ? 'Edit' : ''}}
            {{this.editorInfo?.name == 'old-editor' ? 'Edit with new editor' : ''}}
            {{this.editorInfo?.name !== 'old-editor' ? 'Edit' : ''}}
          </button>
        </div>
      </div>
      <div class="col-md-4 d-flex justify-content-center">
        <app-device-toolbar
          (onPageSizeChange)="onPageSizeSelect($event)"
          (openCodeEditor)="onEditHtml()"></app-device-toolbar>
      </div>
      <div class="col-md-4">
        <div class="text-right">
          <button *ngIf="f.template_type_id?.value != 2" [disabled]="!isTemplateLoaded" (click)="previewTemplate()"
                  class="btn btn-default mr-2">
            Preview
          </button>
          <button [disabled]="!isTemplateLoaded && f.template_type_id?.value != 2" (click)="saveClientTemplate()"
                  class="btn btn-primary mr-2">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
