<app-new-email-editor
  *ngIf="showEditor['email-builder-js']"
  [template]="templateData"
  [variables]="variables"
  (EditorStateChanged)="onEditorStateChanged($event, 'email-builder-js')"></app-new-email-editor>
<div class="page-designer-toolbar" xmlns="http://www.w3.org/1999/html">
  <div class="page-designer-toolbar-container" cdkDropListGroup>
    <div class='p-0 bg-white h-100 mt-3'>
      <div class='w-100'>
        <ng-container *ngIf='sectionsStatus["email"]'>
          <div class="row">
            <div class='col-md-11 mx-auto d-none'>
              <p>Email to your influencers asking them to promote their participation. It's a win-win</p>
            </div>
          </div>
          <form [formGroup]="campaignForm">
            <!--            <div class="row" *ngIf="currentUser && currentUser.user_type === '1'">-->
            <div class="row" *ngIf="isAdmin">
              <div class='col-md-10 mb-3 mx-auto'>
                <div class="d-flex align-items-center">
                  <small>Email service provider</small>
                </div>
                <app-creatable-select
                  [selectedValue]="f.email_sender_service_provider_slug.value" [valueKey]="'slug'"
                  [clearable]="currentUser.user_type === '1'"
                  [hideLabel]="true" [onlyBottomBorder]="false" [isCreatable]="false"
                  [placeholder]="'Select a Provider'" [selectOptions]="mailServiceProviders"
                  (optionSelected)="selectMailProvider($event)"></app-creatable-select>
              </div>
            </div>
            <div class="row" *ngIf="!isAdmin">
              <div class='col-md-10 mb-3 mx-auto'>
                <app-creatable-select
                  [selectedValue]="f.email_sender_service_provider_slug.value" [valueKey]="'slug'"
                  [clearable]="currentUser.user_type === '1'"
                  [hideLabel]="true" [onlyBottomBorder]="false" [isCreatable]="false"
                  [placeholder]="'Select a Provider'" [selectOptions]="mailServiceProviders"
                  (optionSelected)="selectMailProvider($event)"></app-creatable-select>
              </div>
            </div>
            <div class="row" *ngIf="!isAdmin && isTestEmailDisable">
              <div class='col-md-10 mb-3 mx-auto'>
                <div class="d-flex align-items-center">
                  <div style='font-size: 12px'>
                    You can use the campaign without sending any emails through the platform. You have access to unique influencer landing pages and share widget through the campaign influencers list.<br />
                    You can even send campaign emails through your own platform and still link it to your Snöball campaign. You just need to export campaign influencers and include these columns in your email template: email_open_tracker_html, share_link_tracker_url </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class='col-md-10 mb-3 mx-auto'>
                <div class="d-flex align-items-center">
                  <small>From Name</small>
                </div>
                <div class='input-group input-group-sm'>
                  <input id="name" class="form-control" placeholder="" type="text" [disabled]='isTestEmailDisable'
                         formControlName="email_from_name">
                </div>
                <div class="bar error-brd" *ngIf="submitted && f.email_from_name.errors">
                  <div class="invalid-text" *ngIf="f.email_from_name.errors.required">Email From Name is required</div>
                </div>
              </div>
            </div>
            <div class="row d-none">
              <div class='col-md-10 mx-auto customize-email'>
                <input id="customize" placeholder="" type="checkbox" (change)="customizeFromAddress()" [disabled]='isTestEmailDisable'
                       [checked]="this.campaignMeta.email_customized">
                <label for="customize">Customize From email</label>
              </div>
            </div>
            <div class="row">
              <div class='col-md-10 mb-3 mx-auto'>
                <div class="d-flex align-items-center">
                  <small>From Email</small>
                </div>
                <div class='input-group input-group-sm'>
                  <input id="email"
                         class="form-control"
                         type="text"
                         placeholder="no-reply@snoball.it"
                         formControlName="email_from"
                         (change)="validateMailAddress(true)"
                         [disabled]='isTestEmailDisable'
                  >
                </div>
                <ng-container *ngIf="f.email_from.value && !isTestEmailDisable">
                  <span class="badge badge-success cursor-pointer" (click)="showVerificationStatus()"
                        *ngIf="fromEmailVerified && fromEmailVerified === 'verified'">
                    Verified (Click to update)
                  </span>
                  <span class="badge badge-danger cursor-pointer" (click)="showVerificationStatus()"
                        *ngIf="fromEmailVerified && fromEmailVerified === 'unverified'">
                    Unverified (Click to verify)
                  </span>
                </ng-container>
                <div class="bar error-brd" *ngIf="submitted && f.email_from.errors">
                  <div class="invalid-text" *ngIf="f.email_from.errors.required">Email From is required</div>
                  <div class="invalid-text" *ngIf="f.email_from.errors.email">Invalid email address</div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="domainVerificationInfo">
              <div class='col-md-10 mb-3 mx-auto'>
                <div class="bg-neutral-first border-2 border-first p-2">
                  <div>
                    Add the following dns records to your domain
                  </div>
                  <div style="overflow-x: scroll">
                    <table class="email-verification-table">
                      <tr>
                        <td>_amazonses.snoball.events</td>
                        <td>TXT</td>
                        <td>rcmeuTgJzNoo3mxR9NhrTO/G4hn+78G+JFNHGxM7LXA=</td>
                      <tr>
                      <tr>
                        <td>fh362wdmi7knbyjksgjd4sse6qvj6vvq._domainkey.snoball.events</td>
                        <td>CNAME</td>
                        <td>fh362wdmi7knbyjksgjd4sse6qvj6vvq.dkim.amazonses.com</td>
                      <tr>
                      <tr>
                        <td>kudrkvdjn6xpnnh5wtpqqmx5b6o6h2zh._domainkey.snoball.events</td>
                        <td>CNAME</td>
                        <td>kudrkvdjn6xpnnh5wtpqqmx5b6o6h2zh.dkim.amazonses.com</td>
                      <tr>
                      <tr>
                        <td>5a4l5dc3inqzmrrq7jiizp44op7ogqye._domainkey.snoball.events</td>
                        <td>CNAME</td>
                        <td>5a4l5dc3inqzmrrq7jiizp44op7ogqye.dkim.amazonses.com</td>
                      <tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class='col-md-10 mb-3 mx-auto'>
                <div class="d-flex align-items-center">
                  <small>Reply To Email</small>
                </div>
                <div class='input-group input-group-sm'>
                  <input id="email_reply_to" class="form-control" placeholder="" type="text" [disabled]='isTestEmailDisable'
                         formControlName="email_reply_to" [autocomplete]="randomStr">
                </div>
                <div class="bar error-brd" *ngIf="submitted && f.email_reply_to.errors">
                  <div class="invalid-text" *ngIf="f.email_reply_to.errors.required">Email reply to is required</div>
                  <div class="invalid-text" *ngIf="f.email_reply_to.errors.email">Invalid email address</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class='col-md-10 mb-3 mx-auto'>
                <div class="d-flex align-items-center">
                  <small>Subject Line</small>
                  <app-variables
                    (onVariableForClick)="onAddVariablesButtonClick($event)" [variableClickFor]="'email_subject'"
                    [isDisabled]="isTestEmailDisable"
                    [variables]="variables"
                    (onVariableClick)="onVariableClick($event, subject)"
                  ></app-variables>
                  <button type="button" class="info-button pt-1" [ngbPopover]="popInfoTemplate" data-html="true"
                          triggers="mouseenter:mouseleave" placement="auto" [container]="'body'"
                          [popoverClass]="'info-popover'" (click)="applyRTLEmbedding()">
                    <i class="fa fa-paragraph"></i>
                  </button>
                  <ng-template #popInfoTemplate>
                    <p class="mb-0">
                      Move the text cursor before the English word inside the RTL text and click the button.
                      This would make the RTL texts to show properly when they're mixed with LTR characters.
                    </p>
                  </ng-template>
                </div>
                <div class='input-group input-group-sm variable-input'>
                  <textarea class="form-control overflow-hidden auto-grow-textarea" #subject
                            id="subject_line" placeholder="" type="text" (input)="autoGrow(subject)"
                            formControlName="email_subject"
                            ></textarea>
                  <!--                  <div class="inputs">-->
                  <!--                    <app-variables-->
                  <!--                      (onVariableForClick)="onAddVariablesButtonClick($event)" [variableClickFor]="'email_subject'"-->
                  <!--                      [isDisabled]="false"-->
                  <!--                      [variables]="variables"-->
                  <!--                      (onVariableClick)="onVariableClick($event)"-->
                  <!--                    ></app-variables>-->
                  <!--                  <app-file-browser [clientId]="campaign?.client_id"-->
                  <!--                                    [projectId]="campaign?.project_id"></app-file-browser>-->
                  <!--                  </div>-->
                </div>
                <div class="bar error-brd" *ngIf="submitted && f.emails_source_message.errors">
                  <div class="invalid-text" *ngIf="f.emails_source_message.errors.required">Email subject is required</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class='col-md-10 mb-3 mx-auto'>
                <div class="d-flex align-items-center">
                  <small>Email Source Message</small>
                  <app-variables
                    (onVariableForClick)="onAddVariablesButtonClick($event)" [variableClickFor]="'emails_source_message'"
                    [isDisabled]="isTestEmailDisable"
                    [variables]="variables"
                    (onVariableClick)="onVariableClick($event, emails_source_message)"
                  ></app-variables>
                  <button type="button" class="info-button pt-1" [ngbPopover]="popInfoTemplate" data-html="true"
                          triggers="mouseenter:mouseleave" placement="auto" [container]="'body'"
                          [popoverClass]="'info-popover'" (click)="applyRTLEmbedding()">
                    <i class="fa fa-paragraph"></i>
                  </button>
                  <ng-template #popInfoTemplate>
                    <p class="mb-0">
                      Move the text cursor before the English word inside the RTL text and click the button.
                      This would make the RTL texts to show properly when they're mixed with LTR characters.
                    </p>
                  </ng-template>
                </div>
                <div class='input-group input-group-sm variable-input'>
                  <textarea class="form-control overflow-hidden auto-grow-textarea" #emails_source_message
                            id="emails_source_message" [placeholder]="" type="text" (input)="autoGrow(emails_source_message)"
                            formControlName="emails_source_message"
                  ></textarea>
                  <!--                  <div class="inputs">-->
                  <!--                    <app-variables-->
                  <!--                      (onVariableForClick)="onAddVariablesButtonClick($event)" [variableClickFor]="'email_subject'"-->
                  <!--                      [isDisabled]="false"-->
                  <!--                      [variables]="variables"-->
                  <!--                      (onVariableClick)="onVariableClick($event)"-->
                  <!--                    ></app-variables>-->
                  <!--                  <app-file-browser [clientId]="campaign?.client_id"-->
                  <!--                                    [projectId]="campaign?.project_id"></app-file-browser>-->
                  <!--                  </div>-->
                </div>
                <div class="bar error-brd" *ngIf="submitted && f.emails_source_message.errors">
                  <div class="invalid-text" *ngIf="f.emails_source_message.errors.required">Email source message is required</div>
                </div>
              </div>
            </div>
          </form>
          <div class="row" *ngIf="isOldEditor">
            <div class='col-md-10 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small>Button Text</small>
<!--                <app-variables-->
<!--                  [isDisabled]="false"-->
<!--                  [variables]="variables"-->
<!--                  (onVariableClick)="onButtonVariableClick($event, 'buttonText')"-->
<!--                ></app-variables>-->
              </div>
              <div class='input-group input-group-sm variable-input'>
                <input id="buttonText" class="form-control" placeholder="" type="text" [(ngModel)]="buttonText" [autocomplete]="randomStr" [disabled]='isTestEmailDisable'>
                <!--                <div class="inputs">-->
                <!--                  <app-variables-->
                <!--                    [isDisabled]="false"-->
                <!--                    [variables]="variables"-->
                <!--                    (onVariableClick)="onButtonVariableClick($event, 'buttonText')"-->
                <!--                  ></app-variables>-->
                <!--                  <app-file-browser [clientId]="campaign?.client_id"-->
                <!--                                    [projectId]="campaign?.project_id"></app-file-browser>-->
                <!--                </div>-->
              </div>
            </div>
          </div>
          <div class="row" *ngIf="isOldEditor">
            <div class='col-md-10 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small>Button Color</small>
              </div>
              <div class='input-group input-group-sm custom-color-picker'>
                <input id="background_color" style="height: 30px;" [value]="buttonColor" [(ngModel)]="buttonColor" [autocomplete]="randomStr" [disabled]='isTestEmailDisable'
                       type="text" class='form-control border-0'
                       appColorPicker (change)="buttonColor = $event.target.value"/>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="isOldEditor">
            <div class='col-md-10 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small>Button Text Color</small>
              </div>
              <div class='input-group input-group-sm custom-color-picker'>
                <input id="button_font_color" style="height: 30px;" [value]="buttonTextColor" [(ngModel)]="buttonTextColor" [autocomplete]="randomStr" [disabled]='isTestEmailDisable'
                       type="text" class='form-control border-0'
                       appColorPicker (change)="buttonTextColor = $event.target.value"/>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="isOldEditor">
            <div class='col-md-10 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small>Background Color</small>
              </div>
              <div class='input-group input-group-sm custom-color-picker'>
                <input id="email_background_color" style="height: 30px;" [value]="emailBackgroundColor" [autocomplete]="randomStr" [disabled]='isTestEmailDisable'
                       [(ngModel)]="emailBackgroundColor"
                       type="text" class='form-control border-0'
                       appColorPicker (change)="emailBackgroundColor = $event.target.value"/>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="isOldEditor">
            <div class='col-md-10 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small>Link Color</small>
              </div>
              <div class='input-group input-group-sm custom-color-picker'>
                <input id="link_color" style="height: 30px;" [value]="linkColor" [(ngModel)]="linkColor" [autocomplete]="randomStr" [disabled]='isTestEmailDisable'
                       type="text" class='form-control border-0'
                       appColorPicker (change)="linkColor = $event.target.value"/>
              </div>
            </div>
          </div>
          <div class="row" style="display: none">
            <div class='col-md-10 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small>Link Hover Color</small>
              </div>
              <div class='input-group input-group-sm custom-color-picker'>
                <input id="link_hover_color" style="height: 30px;" [value]="linkHoverColor" [(ngModel)]="linkHoverColor" [autocomplete]="randomStr" [disabled]='isTestEmailDisable'
                       type="text" class='form-control border-0'
                       appColorPicker (change)="linkHoverColor = $event.target.value"/>
              </div>
            </div>
          </div>
<!--          <div class="row">-->
<!--            <div class='col-md-10 mb-3 mx-auto'>-->
<!--              <div class="d-flex align-items-center">-->
<!--                <small>Paragraph Spacing (Bottom)</small>-->
<!--              </div>-->
<!--              <div class='input-group input-group-sm variable-input'>-->
<!--                <input id="paragraph_space" style="height: 30px;" class='form-control' type='number'-->
<!--                       [(ngModel)]="paragraphSpacing" data-field='max_width'-->
<!--                       data-unit="px"/>-->
<!--                <div class='input-group-append'>-->
<!--                  <span class='input-group-text span-px'>px</span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="row">-->
<!--            <div class='col-md-10 mb-3 mx-auto'>-->
<!--              <div class="d-flex align-items-center">-->
<!--                <small>Button Spacing (Left, Right)</small>-->
<!--              </div>-->
<!--              <div class='input-group input-group-sm variable-input'>-->
<!--                <input id="max_width" style="height: 30px;" class='form-control' type='number'-->
<!--                       [(ngModel)]="buttonSpacing" data-field='max_width'-->
<!--                       data-unit="px"/>-->
<!--                <div class='input-group-append'>-->
<!--                  <span class='input-group-text span-px'>px</span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <div class="row" style="display: none">
            <div class='col-md-10 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Share Link</small>
              </div>
              <div class='input-group input-group-sm variable-input'>
                <input class="form-control" type='text' [disabled]="isTestEmailDisable || !campaignMeta['share_link_customized']"
                       [(ngModel)]="campaignMeta['share_link']"/>
                <div class="inputs">
                  <app-variables
                    [isDisabled]="!campaignMeta['share_link_customized']"
                    [variables]="variables"
                    (onVariableClick)="updateValue($event, 'share_link')"
                  ></app-variables>
                  <!--                  <app-file-browser [clientId]="campaign?.client_id"-->
                  <!--                                    [projectId]="campaign?.project_id"></app-file-browser>-->
                </div>
              </div>
            </div>
          </div>
          <div class='row' style="display: none">
            <div class='col-md-10 mx-auto customize-email text-center'>
              <button class="btn btn-primary btn-sm" type="button" (click)="shareWidgetPreview()">
                Share Widget Preview
              </button>
            </div>
          </div>

          <div class="p-3 email-body" style="display: none">
            <app-social-share-preview class="smaller-text" [socialMedia]="'Facebook'"
                                      [title]="campaignMeta?.title"
                                      [image]="campaignMeta?.image_template && campaign?.social_template?.thumbnail ? campaign.social_template.thumbnail : campaignMeta?.image"
                                      [message]="campaignMeta?.social_networks?.facebook?.message || 'Your message here'"
                                      [description]="campaignMeta?.description || 'Your description here...'"
                                      [url]="campaign ? campaign.project.subdomain : 'my-event.snoball.events'">
            </app-social-share-preview>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div class="right-content">
  <div class="container-fluid pt-3 mobile-p0 campaign-creation-steps">
    <div class="rightBody">
      <div class="col-12">
        <app-status-bar [loading]="loader$ | async" [step]="step" [skipLanding]="campaign?.landing_page_status === 'skipped'"
                        (SaveButtonClick)="onSaveButtonClick()"></app-status-bar>
        <div class="card card-box card-margin custom-fullscreen-card">
          <div class="card-header py-3">
            <div class="card-header--title">
              <div class="row">
                <div class="col-md-12">
                  <span class="font-size-lg font-weight-bold">The Ask Email</span> <span class="font-size-md ml-2"> Email your influencers to tell them to promote the campaign. It's a
                  win-win</span>
                </div>
              </div>
            </div>
            <div class="card-header--actions pull-right">
            </div>
          </div>
          <div class="card-body pb-0">
            <div class='row custom-card-body'>
              <div class="col-10">
                <small><b>From Name:</b> {{campaignForm.value.email_from_name}}</small><br/>
                <small><b>From Email:</b> {{campaignForm.value.email_from || 'no-reply@snoball.it'}}
                </small><br/>
                <small><b>Reply To:</b> {{campaignForm.value.email_reply_to || 'no-reply@snoball.it'}}
                </small><br/>
                <small><b>Subject Line:</b> {{campaignForm.value.email_subject}}</small><br/>
              </div>
              <div class="col-2">
              </div>
            </div>
            <br/>
            <div class="row bg-heavy-rain">
              <div class='col-12' *ngIf="template && variableLoaded" style="padding: 0;">
                <app-new-editor-preview *ngIf="!isOldEditor" [template]="templateData"></app-new-editor-preview>

                <app-editor *ngIf="isOldEditor" #editor [template]='template' [customVariables]='variables'
                            [templateButtonText]="buttonText"
                            [templateButtonTextColor]="buttonTextColor"
                            [templateButtonSpacing]="buttonSpacing"
                            [templateListSpacing]="listSpacing"
                            [templateParagraphSpacing]="paragraphSpacing"
                            [templateButtonColor]="buttonColor"
                            [templateLinkColor]="linkColor"
                            [templateLinkHoverColor]="linkHoverColor"
                            [templateBackgroundColor]="emailBackgroundColor"
                            [templateButtonLink]="campaignMeta['share_link']" (isEditorLoaded)='editorLoaded = $event'
                            [influencerTypeId]='influencerType' [allowReordering]='false' [onChange]='onEditorChange'>
                </app-editor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="operation-box">
  <div class="col-md-12">
    <div class="row">
      <div class="col d-flex justify-content-left align-items-center">
        <div class="text-left">
          <button (click)="onBackButtonClick()" class="btn btn-default mr-2" type="button">
            Back
          </button>
          <button [disabled]="!editorLoaded" *ngIf="this.template?.id"
                  class="btn btn-default mr-2"
                  (click)="showTemplateRevisions()">
            Undo All
          </button>
          <button *ngIf="this.template?.id"
                  class="btn btn-default"
                  (click)="showNewEditor()">
            {{!(this.isOldEditor && this.isAdmin) ? 'Edit' : 'Edit with new editor'}}
          </button>
        </div>
      </div>
      <div class="col d-flex justify-content-center align-items-center">
        <div class="text-center">
          <small>Sending to <b>{{influencerCount}} influencers</b> ({{this.influencerType?.name}}) in segment
            <b>{{this.campaign?.segments[0]?.name || ''}}</b></small>
        </div>
      </div>
      <div class="col d-flex justify-content-end align-items-center">
        <div class="text-right">
          <button [disabled]="isTestEmailDisable || (loader$ | async) || !editorLoaded" (click)="onSendTestEmailButtonClick()"
                  class="btn btn-default mr-2"
                  type="button">
              <span><i class="fa fa-spinner fa-spin mr-2"
                       *ngIf="(loader$ | async) || !editorLoaded"></i>Send Test Email</span>
          </button>
          <button [disabled]="(loader$ | async) || !editorLoaded"
                  class="btn btn-default mr-2 d-none" (click)="previewEmailTemplate()"
                  type="button">
            <span><i class="fa fa-spinner fa-spin mr-2"
                     *ngIf="(loader$ | async) || !editorLoaded"></i>Preview</span>
          </button>
          <button [disabled]="(loader$ | async) || !editorLoaded" (click)="onNextButtonClick()"
                  class="btn btn-primary mr-2" type="button">
            <span><i class="fa fa-spinner fa-spin mr-2" *ngIf="(loader$ | async) || !editorLoaded"></i>Next</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #sendTestEmail let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Send a test email</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="testMailForm" class=" w-100">
          <div class="mt-4 mx-0 w-100">
            <div
              class="w-100"
              formArrayName="recipients"
              *ngFor="let recipient of testMailForm.get('recipients')['controls']; let i = index;">
              <div [formGroupName]="i" class="mt-2 w-100 position-relative">
                <input  type="email"
                        [ngClass]="{minusIcon: i >=1}"
                        class="px-2 w-100 input" formControlName="email" placeholder="Email Address" autoComplete="none">
                <div class=" position-absolute h-100 cursor-pointer" [ngClass]="{'floating-element': i >=1}" (click)="removeRecipient(i)"></div>
              </div>
            </div>
            <button  class="input add-recipient no-box-shadow mt-2 pl-2 w-100 text-left" type="button" (click)="addRecipient()">Add Another Email</button>
                </div>
        </form>
                  <!--        <div class="floating-label">-->
<!--          <input class="floating-input" (keydown.enter)="sendTestEmails(modal)" [(ngModel)]="testEmail"-->
<!--                 (ngModelChange)="testEmailError = ''" placeholder=" "-->
<!--                 type="email"/>-->
<!--          <span class="highlight"></span>-->
<!--          <label>Send test email to<span class="red">*</span></label>-->
<!--          <div *ngIf="testEmailError" class="bar error-brd">-->
<!--            <div class="invalid-text">{{testEmailError}}</div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="floating-label">
          <input class="floating-input"  (keydown.enter)="sendTestEmails(modal)" [(ngModel)]="sampleInfluencerEmail"
                 (ngModelChange)="sampleInfluencerEmailError = ''" placeholder=" "
                 type="email"/>
          <span class="highlight"></span>
          <label>Sample influencer email</label>
          <div *ngIf="sampleInfluencerEmailError" class="bar error-brd">
            <div class="invalid-text">{{sampleInfluencerEmailError}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class='row'>
      <div class='col-md-11 customize-email include-checkbox'>
        <input id="customizePage" placeholder="" type="checkbox"
               [(ngModel)]="isIncludeContent">
        <label for="customizePage">Include additional content (optional)</label>
      </div>
    </div>
    <div *ngIf="isIncludeContent" class="row">
      <div class="col-md-12">
        <textarea class="form-control auto-grow-textarea email-textarea" [(ngModel)]="additionalContent"
                  id="additional_content" placeholder="" type="text" rows="8">
        </textarea>
      </div>
    </div>
  </div>
  <input type="hidden" ngbAutofocus>
  <div class="modal-footer">
    <button (click)="modal.dismiss('Close clicked');" class="btn btn-default mr-2">
      <span>Cancel</span>
    </button>
    <button type="submit" (click)="sendTestEmails(modal)" class="btn btn-primary mr-2">
      <span>Send test</span>
    </button>
  </div>
</ng-template>
