import { Component, Input, OnChanges, SimpleChanges, ViewChild, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-new-editor-preview',
  templateUrl: './editor-preview.component.html',
  styleUrls: ['./editor-preview.component.scss']
})
export class NewEditorPreviewComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() template: any;
  @ViewChild('previewIframe') iframe: ElementRef | undefined;

  iframeSrc: string = '';
  editorUrl: string = '';

  constructor() {
    this.editorUrl = `${environment.apiUrl}/api/v1/editor/email/template`;
  }

  ngOnInit(): void {
    window.addEventListener("message", this.handleEditorMessages.bind(this));
  }

  ngAfterViewInit(): void {
    if (this.iframe) {
      this.iframeSrc = `${this.editorUrl}?isPreview=true&topPanel=false`;
      this.iframe.nativeElement.src = this.iframeSrc;

      this.iframe.nativeElement.onload = () => {
        if (!!this.template) {
          this.iframe.nativeElement.contentWindow.postMessage({
              action: 'init-data',
              origin: 'sb-email-template',
              template_json_body: this.template.body_json,
          }, '*');
        } else {
          this.iframe.nativeElement.contentWindow.postMessage({
              action: 'init-data',
              origin: 'sb-email-template',
              template_json_body: null,
          }, '*');
        }
      };
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['template'] && this.template && this.iframe) {
      this.iframe.nativeElement.height = 150;
      this.iframeSrc = `${this.editorUrl}?isPreview=true&topPanel=false`;
      this.iframe.nativeElement.src = this.iframeSrc;
    }
  }

  handleEditorMessages(event: MessageEvent): void {
    if (event.data.origin  === "email-preview") {
      if (event.data.action === "height-changed") {
        this.iframe.nativeElement.height = event.data.height;
      }
    }
  }

}

