import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-new-social-editor',
    templateUrl: './social-editor.component.html',
    styleUrls: ['./social-editor.component.scss']
  })
export class NewSocialEditorComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() template: any;
  @Input() variables: any;
  @Output() EditorStateChanged = new EventEmitter();
  @ViewChild('editorIframe') iframe: ElementRef | undefined;
  currentUser: any = {};

  iframeSrc: string = '';
  editorUrl: string = '';

  constructor() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.editorUrl = `${environment.apiUrl}/api/v1/editor/social`;
  }

  ngOnInit(): void {
    window.addEventListener("message", this.handleEditorMessages.bind(this));
  }

  ngAfterViewInit(): void {
    if (this.iframe) {
      this.iframeSrc = `${this.editorUrl}?topPanel=false`;
      this.iframe.nativeElement.src = this.iframeSrc;

      this.iframe.nativeElement.onload = () => {
        if (!!this.template) {
          this.iframe.nativeElement.contentWindow.postMessage({
              action: 'init-data',
              origin: 'sb-social-template',
              template_json_body: this.template.body_json,
              variables: this.variables.influencer,
              accessToken: this.currentUser.access_token
          }, '*');
        } else {
          this.iframe.nativeElement.contentWindow.postMessage({
              action: 'init-data',
              origin: 'sb-social-template',
              template_json_body: null,
              variables: this.variables.influencer,
              accessToken: this.currentUser.access_token
          }, '*');
        }
      };
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['template'] && this.template && this.iframe) {
      this.iframe.nativeElement.src = this.iframeSrc;
    }
  }

  handleEditorMessages(event: MessageEvent): void {
    if (event.data.origin  === "social-editor") {
      if (event.data.action === "save") {
        this.EditorStateChanged.emit({
          body: event.data.body,
          body_json: event.data.body_json,
          action: 'save'
        });
      }

      if (event.data.action === "close") {
        this.EditorStateChanged.emit({
          action: 'close'
        });
      }
    }
  }

}
