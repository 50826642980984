import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from "@environments/environment";
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-new-variables',
  templateUrl: './new-variables.component.html',
  styleUrls: ['./new-variables.component.scss']
})
export class NewVariablesComponent implements OnInit {

  @Output() public onVariableForClick: EventEmitter<any> = new EventEmitter();
  @Output() public onVariableClick: EventEmitter<any> = new EventEmitter();
  @Input() variableClickFor: string;
  @Input() isDisabled = false;
  @Input() hideText = true;
  @Input() variables: any;
  @ViewChild('variablesList', {static: true}) variablesList: TemplateRef<any>;
  @ViewChild('iframe') iframe!: ElementRef<HTMLIFrameElement>;

  sharevariableListUrl: any = '';
  variableListUrl: SafeResourceUrl;
  constructor(
    private _modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    window.addEventListener('message', this.receiveMessage.bind(this), false);
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.receiveMessage.bind(this), false);
  }

  receiveMessage(event: MessageEvent): void {
    if (event.data.type === 'variable' && event.data.target === this.variableClickFor) {
      this.handleUserData(event.data.data);
    }
  }

  handleUserData(variable: any): void {
    this.onClickVariable(variable)
    // this.cdr.detectChanges();
  }

  onAddVariablesButtonClick(variablesList) {
   this.sharevariableListUrl = environment.frontUrl.replace('/#', '') + `/b/variable/template?type=variable&target=${this.variableClickFor}`;
    if (this.sharevariableListUrl) {
      setTimeout(() => {
        this.variableListUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.sharevariableListUrl);
        // this.cdr.detectChanges();
      });
    }

    const modelRef: any = this._modalService.open(variablesList, {
      centered: false,
      size: 'xl',
      keyboard: true
    });
    modelRef.result.then(
      (result) => {},
      (reason) => {}
    );
    this.onVariableForClick.emit(this.variableClickFor);
  }

  onClickVariable(variable): void {
    this.onVariableClick.emit(variable);
    this._modalService.dismissAll();
  }
}
