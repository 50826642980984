import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {Client, User} from '@app/modules/shared/models';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {TitleService} from '@app/services/title.service';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Constants} from '@app/consts';

import * as routes from '@app/routes';
import {select, Store} from '@ngrx/store';
import {
  ClientsListAll,
  getClientError,
  getClients,
  getFileUploadProgress,
  getLoggedInUser,
  getProject,
  getProjectError,
  getProjectIndustries,
  getProjectSlug,
  getSignedUrl,
  getSignedUrlError,
  getTemplateSectionAssets,
  getToken,
  getUploadToSignedUrl,
  getUploadToSignedUrlError,
  getVariableError,
  getVariables,
  IAuthenticationState,
  IClientState,
  IFileUploadState,
  IProjectState,
  ITemplateSectionState,
  IVariableState,
  ProjectCreate,
  ProjectIndustries,
  ProjectValidateSlug,
  ResetFileState,
  SignedUrl,
  TemplateSectionGetAssets,
  UploadToSignedUrl,
  VariableList
} from '@app/stores';
import {takeUntil} from 'rxjs/operators';
import * as moment from 'moment-timezone';
import {TzDatePipe} from '@app/modules/shared/pipes/tz-date.pipe';
import {environment} from '@environments/environment';
import {TemplateSectionService} from '@app/services/template-section.service';
declare var FroalaEditor: any;
function getCodeMirror() {
  // @ts-ignore
  return window.CodeMirror;
}

@Component({
  selector: 'app-event-add',
  templateUrl: './project-add.component.html',
  styleUrls: ['./project-add.component.css'],
  providers: [Constants]
})
export class ProjectAddComponent implements OnInit, OnDestroy {

  public min: Date = new Date();
  public max: Date = this.min;

  currentUser: User;
  isAdmin = false;
  selectedFiles: FileList;
  event: Event;
  planners: Client[] = [];
  imageBinary: any;
  addSocialClick = true;
  currentUserSubscription: Subscription;
  addProjectForm: UntypedFormGroup;
  submitted = false;
  timezones: Object[] = [];
  industries: Object[] = [];
  slugdata: string;
  title = 'Add Project';
  selectedClient = null;
  public step;

  industryList: any;
  eventList =  [{id: 'in-person', name: 'In Person'},
    {id: 'virtual', name: 'Virtual'},
    {id: 'hybrid', name: 'Hybrid'},
    {id: 'membership', name: 'Membership'}];
  isPaidList =  [{id: 'free', name: 'Free'},
    {id: 'paid', name: 'Paid'},
    {id: 'hybrid', name: 'Hybrid'}];

  imageProject = '../../../../assets/images/brand-logo.png';
  unsubscriber = new Subject();
  public variables = {};
  imageFile = null;
  imageSignedUrl = null;
  fileUploadProgress = 0;
  public editor: any;
  editorLoaded = false;
  token = null;
  isLoadEditor = false;
  variablesFor: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private authenticationStore: Store<IAuthenticationState>,
    private projectStore: Store<IProjectState>,
    private clientStore: Store<IClientState>,
    private fileUploadStore: Store<IFileUploadState>,
    private variableStore: Store<IVariableState>,
    public constants: Constants,
    private toastr: ToastrService,
    private titleService: TitleService,
    private elRef: ElementRef,
    private tzDatePipe: TzDatePipe,
    private templateSectionService: TemplateSectionService,
    private templateSectionStore: Store<ITemplateSectionState>,
  ) {
    this.authenticationStore.pipe(select(getToken))
        .pipe(takeUntil(this.unsubscriber))
        .subscribe(newToken => {
          if (newToken) {
            this.token = newToken;
          }
        });
    this.fileUploadStore.dispatch(ResetFileState({params: {error: '', file: null}}));
    this.subscribeStore();
    this.timezones = constants.timezone;
    this.titleService.set(this.title);
  }

  get f() {
    return this.addProjectForm.controls;
  }

  subscribeStore() {
    this.authenticationStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(user => {
          if (user) {
            this.currentUser = user;
            this.isAdmin = this.currentUser.role_user[0].role_id === 1;
          }
        }
      );

    this.templateSectionStore.pipe(select(getTemplateSectionAssets))
        .pipe(takeUntil(this.unsubscriber))
        .subscribe(templateSectionAssets => {
          if (templateSectionAssets) {
            // if (Object.keys(this.variables).length > 0) {
            //   this.enableEditor(templateSectionAssets.assets?.css);
            // } else {
            //   setTimeout(() => {
            //     this.enableEditor(templateSectionAssets.assets?.css);
            //   }, 1000);
            // }
            this.enableEditor(templateSectionAssets.assets?.css);

          }
        });

    this.projectStoreSubscribe();
    this.userStoreSubscribe();
    this.fileStoreSubscribe();
    this.variableStoreSubscribe();
  }

  variableStoreSubscribe() {
    this.variableStore.pipe(select(getVariableError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.variableStore.pipe(select(getVariables))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(variables => {
        if (variables) {
          const result = [];
          Object.entries(variables).forEach(([key, value]) => {
            if (value.hasOwnProperty('group') && value['group']) {
              if (!result[value['group']]) {
                result[value['group']] = [];
              }
              result[value['group']].push({
                name: value['title'].replace(value['group'].toString().toLowerCase().trim(), ''),
                key: value['alias'] ? `#${value['alias']}#` : value['key'],
                valueType: value['valueType'],
                example: value['sample']
              });
            } else {
              if (!result['generic']) {
                result['generic'] = [];
              }
              result['generic'].push({
                name: value['title'],
                key: value['key'],
                valueType: value['valueType'],
                example: value['sample']
              });
            }
          });
          this.variables = result;
        }
      });
  }

  projectStoreSubscribe() {
    this.projectStore.pipe(select(getProjectError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });

    this.projectStore.pipe(select(getProjectSlug))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(slug => {
        if (slug && slug === 'invalid') {
          this.addProjectForm.controls['slug'].setValue('');
          this.addProjectForm.controls['hashtag'].setValue('');
          this.toastr.error('Please change the slug, it\'s already taken', 'Error');
        }
      });

    this.projectStore.pipe(select(getProject))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(project => {
        if (project) {
          this.router.navigate([routes.campaigns({projectId: project.id})]);
        }
      });

    this.projectStore.pipe(select(getProjectIndustries))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(industries => {
        if (industries) {
          this.industryList = industries;
        }
      });
  }

  userStoreSubscribe() {
    this.clientStore.pipe(select(getClientError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error);
        }
      });
    this.clientStore.pipe(select(getClients))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(clients => {
        if (clients && clients.length > 0) {
          this.planners = clients;
          if (this.addProjectForm && !this.isAdmin) {
            if (this.planners[0]?.require_google_utm_for_cta_links) {
              //this.addProjectForm.controls['google_utm_params'].setValue(`utm_source=snoball&utm_medium=referral&utm_term=referral-marketing`);
            }
          }
        }
      });
  }

  fileStoreSubscribe() {
    this.fileUploadStore.pipe(select(getSignedUrlError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.fileUploadStore.pipe(select(getUploadToSignedUrlError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.fileUploadStore.pipe(select(getFileUploadProgress))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data !== undefined && data !== null) {
          this.fileUploadProgress = data;
        }
      });
    this.fileUploadStore.pipe(select(getSignedUrl))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data && data.url) {
          this.imageSignedUrl = data.url;
        }
      });
    this.fileUploadStore.pipe(select(getUploadToSignedUrl))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data && this.imageSignedUrl) {
          const image = this.imageSignedUrl.split('?')[0];
          this.addProjectForm.patchValue({image});
          this.imageSignedUrl = null;
          this.imageFile = null;
          this.addProjectData();
        }
      });
  }

  ngOnInit() {
    this.variableStore.dispatch(VariableList({params: {}}));
    this.step = {title: 'Project', params: {}};
    this.addSocialClick = true;
    this.loadAllPlanners();
    this.getIndustries();
    this.addProjectForm = this.formBuilder.group({
      title: ['', Validators.required],
      ...(this.isAdmin ? { client_id: ['', Validators.required]} : {}),
      venue: [''],
      image: [null],
      event_type: ['', Validators.required],
      is_paid: ['', Validators.required],
      industry: [''],
      // ga_code: [''],
      timezone: [''],
      start_date: ['', Validators.required],
      start_time: [''],
      end_date: ['', Validators.required],
      end_time: [''],
      activation_starts_at: ['', Validators.required],
      activation_ends_at: ['', Validators.required],
      registration_url: ['', [Validators.required, Validators.pattern(this.constants.urlRegex)]],
      website_url: ['', [Validators.required, Validators.pattern(this.constants.urlRegex)]],
      google_utm_params: [''],
      emails_source_message: [''],
      register_average_price: [null],
      report_analysis: [''],
      hashtag: [''],
      facebook: ['', Validators.pattern(this.constants.urlRegex)],
      twitter: ['', Validators.pattern(this.constants.urlRegex)],
      linkedin: ['', Validators.pattern(this.constants.urlRegex)],
      instagram: ['', Validators.pattern(this.constants.urlRegex)],
      description: [''],
      slug: ['', Validators.required],
      created_by: [this.currentUser.id, [Validators.required]],
      planner_id: [''],
      dates: this.formBuilder.array([this.addDatesFormGroup()]),
      socials: this.formBuilder.array([]),
      fonts: this.formBuilder.array([this.addFontsFormGroup()]),
      custom_1: [''],
      custom_2: [''],
      custom_3: [''],
      custom_4: [''],
      custom_5: [''],
      custom_6: [''],
      custom_7: [''],
      custom_8: [''],
      custom_9: [''],
      custom_10: [''],
      exclude_emails: [''],
      exclude_ips: [''],
      notes: [''],
      sessions_can_be_updated_influencer: [false]
    });

    this.addProjectForm.get('start_date').valueChanges.subscribe(value => {
      this.max = value;
      const endDate = this.addProjectForm.get('end_date').value;
      if (value && endDate && Math.floor((Date.parse(value) - Date.parse(endDate)) / 86400000) > 0) {
        this.addProjectForm.patchValue({end_date: null, end_time: null});
      }
    });
    this.addProjectForm.get('activation_starts_at').valueChanges.subscribe(value => {
      const endDate = this.addProjectForm.get('activation_ends_at').value;
      if (value && endDate && Math.floor((Date.parse(value) - Date.parse(endDate)) / 86400000) > 0) {
        this.addProjectForm.patchValue({activation_ends_at: null});
      }
    });
  }

  ngAfterViewInit() {
    this.getAssets();
  }

  generateSlug(): void {
    this.slugdata = this.convertToSlug(this.addProjectForm.get('title').value);
    this.addProjectForm.get('slug').setValue(this.slugdata);
    this.addProjectForm.get('hashtag').setValue(this.slugdata);
  }

  onClientChange(event): void {
    this.addProjectForm.controls['google_utm_params'].setValue(null);
    if (event?.value) {
      this.addProjectForm.patchValue({ client_id: event.value });
      this.selectedClient = this.planners.find(client => client.id === event.value);
      if (this.selectedClient?.require_google_utm_for_cta_links) {
        //this.addProjectForm.controls['google_utm_params'].setValue(`utm_source=snoball&utm_medium=referral&utm_term=referral-marketing`);
      }
    }
  }
  convertToSlug(Text) {
    return Text.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-');
  }

  public validateSlug() {
    if (!this.addProjectForm.get('slug').value) {
      return;
    }
    this.addProjectForm.get('hashtag').setValue(this.addProjectForm.get('slug').value);
    this.projectStore.dispatch(ProjectValidateSlug({slug: this.addProjectForm.get('slug').value}));
  }

  addSocialsFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      social_label: [''],
      social_value: ['']
    });
  }

  addDatesFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      important_text: [],
      important_date: []
    });
  }

  addFontsFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      name: []
    });
  }

  addDatesButtonClick(): void {
    (<UntypedFormArray>this.addProjectForm.get('dates')).push(this.addDatesFormGroup());
  }

  // ======================== Social Part functions
  addSocialsButtonClick(): void {
    this.addSocialClick = false;
    (<UntypedFormArray>this.addProjectForm.get('socials')).push(
      this.addSocialsFormGroup()
    );
  }

  removeDatesButtonClick(index: number): void {
    const control = <UntypedFormArray>this.addProjectForm.controls['dates'];
    control.removeAt(index);
  }

  removeSocialsButtonClick(index: number): void {
    const control = <UntypedFormArray>this.addProjectForm.controls['socials'];
    control.removeAt(index);
  }

  onFontsLinkChange(e) {
    const {value} = e.target;
    this.addProjectForm.get('fonts').setValue([{name: value}]);
  }

  /**
   * Returns success object that will Project for showing response messages .
   *
   * @param  url  an absolute URL giving the add project page of the Application.
   * @param  name the location of the request, relative to the url.
   * @return      success object with project data.
   */

  addProject() {
    this.submitted = true;
    if (this.addProjectForm.invalid) {
      setTimeout(() => {
        this.scrollToField();
      }, 500);
      return;
    }

    if (this.imageSignedUrl && this.imageFile) {
      this.fileUploadStore.dispatch(UploadToSignedUrl({files: [{url: this.imageSignedUrl, fileData: this.imageFile}]}));
    } else {
      this.addProjectData();
    }
  }

  addProjectData() {
    const params: any = {
      ...this.addProjectForm.value,
      stats_exclude: {
        emails: this.addProjectForm.value.exclude_emails.split(','),
        ips: this.addProjectForm.value.exclude_ips.split(','),
      },
      ...(!this.isAdmin ? {client_id: this.planners[0].id} : {}),
    };

    if (this.addProjectForm.get('timezone').value) {
      params.timezone = this.addProjectForm.get('timezone').value.nameValue;
    }

    let fromDate: any = `${(params.start_date ? moment(params.start_date).format('MM/DD/YYYY') : '') || ''} ${params.start_time || ''}`;
    let toDate: any = `${(params.end_date ? moment(params.end_date).format('MM/DD/YYYY') : '') || ''} ${params.end_time || ''}`;
    if (fromDate.trim()) {
      fromDate = this.tzDatePipe.toUTCMomentObject(moment(fromDate.trim(), `MM/DD/YYYY${params.start_time ? ' h:m A' : ''}`));
      params.start_time = params.start_time ? fromDate.format('hh:mm A') : '';
      params.start_date = params.start_date ? fromDate.format('MM/DD/YYYY') : '';
    } else {
      fromDate = null;
      params.start_date = '';
    }
    if (toDate.trim()) {
      toDate = this.tzDatePipe.toUTCMomentObject(moment(toDate.trim(), `MM/DD/YYYY${params.end_time ? ' h:m A' : ''}`));
      params.end_time = params.end_time ? toDate.format('hh:mm A') : '';
      params.end_date = params.end_date ? toDate.format('MM/DD/YYYY') : '';
    } else {
      toDate = null;
      params.end_date = '';
    }
    params.activation_ends_at = params.activation_ends_at ? moment(params.activation_ends_at).format('MM/DD/YYYY') : '';
    params.activation_starts_at = params.activation_starts_at ? moment(params.activation_starts_at).format('MM/DD/YYYY') : '';
    let htmlBody: any = this.getHtml();
    if (htmlBody === '') {
      params['report_analysis'] = null;
    } else {
      htmlBody = htmlBody.replaceAll(/data-id="[^"]*"/g, '');
      htmlBody = htmlBody.replaceAll(' fp-active', '');
      htmlBody = htmlBody.replaceAll('fp-active', '');
      params['report_analysis'] = htmlBody;
    }
    delete params.exclude_emails;
    delete params.exclude_ips;
    delete params.stats_exclude_hash;
    this.projectStore.dispatch(ProjectCreate({project: params}));
  }

  // START: Function used to fetch industries list.
  public getIndustries() {
    this.projectStore.dispatch(ProjectIndustries());
  }

  uploadLogo(fileInput) {
    fileInput.click();
  }

  timezoneSelect(event: any) {
    this.addProjectForm.patchValue({timezone: event.value});
  }

  onEventImageChanged(event) {
    if (event.target.files && event.target.files.length) {
      this.imageFile = event.target.files[0];
      this.fileUploadStore.dispatch(SignedUrl({fileName: this.imageFile.name, contentType: this.imageFile.type}));
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(this.imageFile);
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      }).then(data => {
        this.imageBinary = data;
      });
    }
  }

  private loadAllPlanners() {
    this.clientStore.dispatch(ClientsListAll());
  }

  updateValue(variable, textFor) {
    const value = this.addProjectForm.get(this.variablesFor).value;
    const varValue = variable.key;
    this.addProjectForm.controls[this.variablesFor].setValue(`${value} ${varValue}`);
  }

  scrollToField() {
    const invalid = this.elRef.nativeElement.querySelector('.invalid-text');
    if (invalid) {
      this.toastr.warning('Some fields have error, please review', 'warning');
      window.scrollBy({
        top: invalid.getBoundingClientRect().top - 150,
        left: 0,
        behavior: 'smooth'
      });
    }
  }


  enableEditor(css: any) {
    const that = this;
    this.templateSectionService.addCustomVariables(FroalaEditor, this.variables);
    const editorOptions = {
      key: environment.froalaEditorKey,
      iframe: true,
      iframeStyleFiles: css,
      attribution: false,
      charCounterCount: false,
      htmlUntouched: true,
      imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
      imageUploadURL: environment.apiUrl + '/api/v1/file',
      fileUploadURL: environment.apiUrl + '/api/v1/file',
      height: 'auto',
      heightMax: 'auto',
      heightMin: 400,
      codeMirror: getCodeMirror(),
      codeMirrorOptions: {
        indentWithTabs: true,
        lineNumbers: true,
        lineWrapping: true,
        mode: 'text/html',
        tabMode: 'indent',
        tabSize: 2
      },
      requestHeaders: {
        'Authorization': `Bearer ${this.token}`
      },
      events: {
        'initialized': function () {
          that.editorLoaded = true;
          that.changeContent('section');
        },
        'image.uploaded': function (response) {
          const jsonResponse = JSON.parse(response);
          this.image.insert(jsonResponse.data.url, false, null, this.image.get(), response);
          return false;
        },
        'file.uploaded': function (response) {
          const jsonResponse = JSON.parse(response);
          this.file.insert(jsonResponse.data.url, jsonResponse.data.file, null);
          return false;
        },
      },
      htmlAllowedEmptyTags: ['textarea', 'a', 'iframe', 'object', 'video', 'style', 'script', '.fa', '.fr-emoticon', '.fr-inner', 'path', 'line', 'hr', 'i', 'span', 'div'],
      linkInsertButtons: ['linkBack', '|', 'linkList', 'linkUrlVariables', 'linkTextVariables'],
      pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'cryptoJSPlugin', 'draggable', 'emoticons', 'entities',
        'file', 'filesManager', 'fontAwesome', 'fontFamily', 'fontSize', 'forms', 'fullscreen', 'help', 'image', 'imageManager', 'imageVariable',
        'inlineClass', 'inlineStyle', 'lineBreaker', 'lineHeight', 'link', 'lists', 'markdown', 'paragraphFormat', 'paragraphStyle',
        'print', 'quickInsert', 'quote', 'save', 'specialCharacters', 'table', 'url', 'video', 'wordPaste', 'html'],
      imageEditButtons: ['imageReplace', 'imageVariableButton', 'imageAlign', 'imageCaption', 'imageRemove', 'imageLink', 'linkOpen',
        'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize'],
      toolbarButtons: ['bold', 'italic', 'textColor', 'backgroundColor', 'paragraphFormat', 'align', 'emoticons', 'insertLink',
        'insertImage', 'undo', 'redo', '-', 'underline', 'subscript', 'superscript', 'fontSize', 'fontFamily', 'insertTable', 'formatOL', 'formatUL',
        'lineHeight', 'insertVideo', 'fontAwesome', 'inlineClass', 'inlineStyle', 'insertFile', 'html']
    };
    this.editor = new FroalaEditor('#analysis_editor', editorOptions);
    if (Object.keys(this.editor).length > 0) {
      this.addCssToFroala();
    } else {
      setTimeout(() => {
        this.editor = new FroalaEditor('#analysis_editor', editorOptions);
      }, 1000);
    }
  }

  changeContent(type = '') {
    this.editor.html.set('');
  }

  addCssToFroala() {
    const css = this.editor.$iframe[0].contentWindow.document.createElement('style');
    css.innerHTML = this.templateSectionService.getFroalaCss();
    this.editor.$iframe[0].contentWindow.document.head.appendChild(css);
    this.isLoadEditor = true;
  }

  getHtml() {
    return this.editor.html.get(true);
  }

  getAssets() {
    this.templateSectionStore.dispatch(TemplateSectionGetAssets({params: {html_mode: 'editor'}}));
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  onAddVariablesForClick(variablesFor) {
    this.variablesFor = variablesFor;
  }
}
