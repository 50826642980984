import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import { Client, Project, User } from '@app/modules/shared/models';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {GlobalprojectService} from '@app/services/global.service';
import {SharedService} from '@app/services/shared.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Constants} from '@app/consts';
import {ToastContainerDirective, ToastrService} from 'ngx-toastr';
import {TitleService} from '@app/services/title.service';
import {select, Store} from '@ngrx/store';
import {
  ClientGet,
  ClientsList,
  ClientsListAll, getClient,
  getClientError, getClients,
  getFileUploadProgress,
  getLoggedInUser, getPaginatedClients,
  getProject,
  getProjectError,
  getProjectIndustries,
  getProjectSuccess,
  getSignedUrl,
  getSignedUrlError, getTemplateSectionAssets, getToken,
  getUploadToSignedUrl,
  getUploadToSignedUrlError,
  getVariableError,
  getVariables,
  IAuthenticationState, IClientState,
  IFileUploadState,
  IProjectState, ITemplateSectionState,
  IVariableState,
  ProjectGet,
  ProjectIndustries,
  ProjectUpdate, ResetClientState,
  ResetFileState,
  ResetProjectState, SignedUrl, TemplateSectionGetAssets, UploadToSignedUrl,
  VariableList
} from '@app/stores';
import {takeUntil} from 'rxjs/operators';
import * as moment from 'moment-timezone';
import {TzDatePipe} from '@app/modules/shared/pipes/tz-date.pipe';
import {environment} from '@environments/environment';
import {TemplateSectionService} from '@app/services/template-section.service';
import {Observable} from "rxjs/Observable";
import {LoaderService} from "@app/services/loader.service";
declare var FroalaEditor: any;
function getCodeMirror() {
  // @ts-ignore
  return window.CodeMirror;
}

@Component({
  selector: 'app-project-view',
  templateUrl: './project-view.component.html',
  styleUrls: ['./project-view.component.css'],
  providers: [Constants]
})
export class ProjectViewComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;

  public min: Date = new Date();
  public max: Date = this.min;
  id: number;
  projectId: number;
  fileId: number;
  mediaType: string;
  currentUser: User;
  project: Project;
  editInfo = true;
  deleted = false;
  public editor: any;
  editorLoaded = false;
  token = null;
  currentUserSubscription: Subscription;
  viewProjectForm: UntypedFormGroup;
  dates: UntypedFormArray;
  socials: UntypedFormArray;
  submitted = false;
  industryList: any;
  eventList =  [{id: 'in-person', name: 'In Person'},
    {id: 'virtual', name: 'Virtual'},
    {id: 'hybrid', name: 'Hybrid'},
    {id: 'membership', name: 'Membership'}];
  isPaidList =  [{id: 'free', name: 'Free'},
    {id: 'paid', name: 'Paid'},
    {id: 'hybrid', name: 'Hybrid'}];
  defaultProjectImage = '../../../../assets/images/brand-logo.png';
  imageProject = '';
  public step = {title: 'Project', params: {}};
  isLoadEditor = false;
  clients: any;
  selectedClient = null;
  loader$: Observable<boolean>;
  title = 'Project View';
  imageBinary: any;
  unsubscriber = new Subject();
  returnUrl;
  public variables = {};
  imageFile = null;
  imageSignedUrl = null;
  fileUploadProgress = 0;
  isAdmin = false;
  variablesFor: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationStore: Store<IAuthenticationState>,
    private projectStore: Store<IProjectState>,
    public constants: Constants,
    private toastr: ToastrService,
    private elRef: ElementRef,
    private sharedService: SharedService,
    private templateSectionService: TemplateSectionService,
    private titleService: TitleService,
    private globalprojectService: GlobalprojectService,
    private fileUploadStore: Store<IFileUploadState>,
    private variableStore: Store<IVariableState>,
    private templateSectionStore: Store<ITemplateSectionState>,
    private tzDatePipe: TzDatePipe,
    private clientStore: Store<IClientState>,
    private loaderService: LoaderService,
  ) {
    this.authenticationStore.pipe(select(getToken))
        .pipe(takeUntil(this.unsubscriber))
        .subscribe(newToken => {
          if (newToken) {
            this.token = newToken;
          }
        });
    this.fileUploadStore.dispatch(ResetFileState({params: {error: '', file: null}}));
    this.projectStore.dispatch(ResetProjectState({params: {error: '', project: null}}));
    this.clientStore.dispatch(ResetClientState({params: {}}));
    this.subscribeStore();
    this.route.paramMap.subscribe(params => {
      this.id = +params.get('id');
      this.loadProject(this.id);
    });

    this.route.queryParams.subscribe(params => {
      this.returnUrl = params.returnUrl;
    });

    this.titleService.set(this.title);
    this.globalprojectService.updateProjectId(this.id);
  }

  subscribeStore() {
    this.authenticationStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(user => {
          if (user) {
            this.currentUser = user;
            this.isAdmin = this.currentUser.role_user[0].role_id === 1;
          }
        }
      );
    this.projectStore.pipe(select(getProjectError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.projectStore.pipe(select(getProjectSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          if (this.submitted) {
            this.submitted = false;
            if (this.returnUrl) {
              if (this.returnUrl === this.router.url) {
                this.router.navigate(['projects']);
              } else {
                this.router.navigateByUrl(this.returnUrl);
              }
            } else {
              this.router.navigate(['projects']);
            }
          }
        }
      });

    this.projectStore.pipe(select(getClientError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error);
        }
      });
    this.clientStore.pipe(select(getPaginatedClients))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(paginatedClients => {
        if (paginatedClients) {
          this.clients = paginatedClients.list;
          const clientIds = this.clients.map(item => item.id);
          if (this.project && this.project.client_id && !clientIds.includes(this.project.client_id)) {
            this.loadClient(this.project.client_id);
          }
        }
      });
    this.clientStore.pipe(select(getClient))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(client => {
        if (client) {
          this.clients = [...this.clients, client]
        }
      });
    this.projectStore.pipe(select(getProjectIndustries))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(industries => {
        if (industries) {
          this.industryList = industries;
        }
      });

    this.projectStore.pipe(select(getProject))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(project => {
        if (project) {
          this.project = Object.assign({}, project);
          this.patchData(this.project.report_analysis);
          this.projectId = this.project.id;
          this.imageBinary = this.project.image || this.defaultProjectImage;

          let fromDate: any = `${this.project.start_date || ''} ${this.project.start_time || ''}`;
          let toDate: any = `${this.project.end_date || ''} ${this.project.end_time || ''}`;
          if (fromDate.trim()) {
            // fromDate = this.tzDatePipe.toLocalMomentObject(moment(fromDate.trim(), `YYYY-MM-DD${this.project.start_time ? ' h:m A' : ''}`));
            fromDate = moment(fromDate.trim(), `YYYY-MM-DD${this.project.start_time ? ' hh:mm A' : ''}`);
            this.project.start_time = this.project.start_time ? fromDate.format('hh:mm A') : '';
            this.project.start_date = this.project.start_date ? fromDate.format('MM/DD/YYYY') : '';
          } else {
            fromDate = null;
            this.project.start_date = '';
          }
          if (toDate.trim()) {
            // toDate = this.tzDatePipe.toLocalMomentObject(moment(toDate.trim(), `YYYY-MM-DD${this.project.end_time ? ' h:m A' : ''}`));
            toDate = moment(toDate.trim(), `YYYY-MM-DD${this.project.end_time ? ' hh:mm A' : ''}`);
            this.project.end_time = this.project.end_time ? toDate.format('hh:mm A') : '';
            this.project.end_date = this.project.end_date ? toDate.format('MM/DD/YYYY') : '';
          } else {
            toDate = null;
            this.project.end_date = '';
          }
          this.project.activation_starts_at = this.project.activation_starts_at ? moment(this.project.activation_starts_at).format('MM/DD/YYYY') : '';
          this.project.activation_ends_at = this.project.activation_ends_at ? moment(this.project.activation_ends_at).format('MM/DD/YYYY') : '';
          if (project?.client?.require_google_utm_for_cta_links) {
            if (!this.project?.google_utm_params) {
             //this.project.google_utm_params = project.client.google_utm_params ? project.client.google_utm_params : 'utm_source=snoball&utm_medium=referral&utm_term=referral-marketing';
            }
          }

          this.viewProjectForm.patchValue({...this.project});
          this.viewProjectForm.patchValue({timezone: this.project.timezone});
          // this.viewProjectForm.patchValue({...project, start_date: this.project.start_date ? moment(this.project.start_date, 'YYYY-MM-DD').format('MM/DD/YYYY') : '',
          //   end_date: this.project.end_date ? moment(this.project.end_date, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''});
          this.viewProjectForm.controls['image'].setValue(this.project.image);
          let statsExclude: any = this.project.stats_exclude;
          while (typeof statsExclude === 'string') {
            statsExclude = JSON.parse(statsExclude);
          }
          if (statsExclude) {
            this.viewProjectForm.controls['exclude_emails'].setValue(statsExclude.emails ? statsExclude.emails.join(',') : '');
            this.viewProjectForm.controls['exclude_ips'].setValue(statsExclude.ips ? statsExclude.ips.join(',') : '');
          }
          if (this.project.dates.length === 0) {
            this.dateFormGroup.push(this.addDatesFormGroup());
          }
          this.project.dates.forEach(index => {
            this.dateFormGroup.push(
              this.formBuilder.group({
                important_text: [index.important_text],
                important_date: [index.important_date]
              })
            );
          });

          if (this.project.socials.length === 0) {
            this.socialFormGroup.push(this.addSocialsFormGroup());
          }
          this.imageProject = this.project.image;

          this.project.socials.forEach(index => {
            this.socialFormGroup.push(
              this.formBuilder.group({
                social_label: [index.label],
                social_value: [index.value]
              })
            );
          });

          if (this.project.evprojectprojectnt && this.project.evprojectprojectnt.fonts) {
            this.project.evprojectprojectnt.fonts.forEach(font => {
              this.fontsFormGroup.push(
                this.formBuilder.group({
                  name: [font.name],
                })
              );
            });
          }

          this.step.params = {
            projectTitle: this.project.title,
            projectId: this.project.id,
          };
        }
      });

    this.templateSectionStore.pipe(select(getTemplateSectionAssets))
        .pipe(takeUntil(this.unsubscriber))
        .subscribe(templateSectionAssets => {
          if (templateSectionAssets) {
            // if (Object.keys(this.variables).length > 0) {
            //   this.enableEditor(templateSectionAssets.assets?.css);
            // } else {
            //   setTimeout(() => {
            //     this.enableEditor(templateSectionAssets.assets?.css);
            //   }, 1000);
            // }
            this.enableEditor(templateSectionAssets.assets?.css);

          }
        });
    this.fileStoreSubscribe();
    this.variableStoreSubscribe();
  }

  variableStoreSubscribe() {
    this.variableStore.pipe(select(getVariableError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.variableStore.pipe(select(getVariables))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(variables => {
        if (variables) {
          const result = [];
          Object.entries(variables).forEach(([key, value]) => {
            if (value.hasOwnProperty('group') && value['group']) {
              if (!result[value['group']]) {
                result[value['group']] = [];
              }
              result[value['group']].push({
                name: value['title'].replace(value['group'].toString().toLowerCase().trim(), ''),
                key: value['alias'] ? `#${value['alias']}#` : value['key'],
                valueType: value['valueType'],
                example: value['sample']
              });
            } else {
              if (!result['generic']) {
                result['generic'] = [];
              }
              result['generic'].push({
                name: value['title'],
                key: value['key'],
                valueType: value['valueType'],
                example: value['sample']
              });
            }
          });
          this.variables = result;
        }
      });
  }


  fileStoreSubscribe() {
    this.fileUploadStore.pipe(select(getSignedUrlError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.fileUploadStore.pipe(select(getUploadToSignedUrlError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.fileUploadStore.pipe(select(getFileUploadProgress))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data !== undefined && data !== null) {
          this.fileUploadProgress = data;
        }
      });
    this.fileUploadStore.pipe(select(getSignedUrl))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data && data.url) {
          this.imageSignedUrl = data.url;
        }
      });
    this.fileUploadStore.pipe(select(getUploadToSignedUrl))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data && this.imageSignedUrl) {
          const image = this.imageSignedUrl.split('?')[0];
          this.viewProjectForm.patchValue({image});
          this.imageSignedUrl = null;
          this.imageFile = null;
          this.updateProjectData();
        }
      });
  }

  get dateFormGroup() {
    return this.viewProjectForm.get('dates') as UntypedFormArray;
  }

  get socialFormGroup() {
    return this.viewProjectForm.get('socials') as UntypedFormArray;
  }

  get fontsFormGroup() {
    return this.viewProjectForm.get('fonts') as UntypedFormArray;
  }

  get f() {
    return this.viewProjectForm.controls;
  }


  // activeForm() {
  //   if (this.editInfo === false) {
  //     $('#basicinfo').addClass('activeForm');
  //     $('.editView-btn1').show();
  //     this.editInfo = true;
  //   } else {
  //     $('#basicinfo').removeClass('activeForm');
  //     $('.editView-btn1').hide();
  //     this.editInfo = false;
  //   }
  // }

  ngOnInit() {
    this.loader$ = this.loaderService.loader$;
    this.variableStore.dispatch(VariableList({params: {}}));
    this.toastr.overlayContainer = this.toastContainer;
    this.getIndustries();
    this.loadAllPlanners();
    this.editInfo = true;
    this.viewProjectForm = this.formBuilder.group({
      title: ['', Validators.required],
      ...(this.isAdmin ? { client_id: ['', Validators.required]} : {}),
      venue: [''],
      industry: [''],
      event_type: ['', Validators.required],
      is_paid: ['', Validators.required],
      image: [''],
      timezone: [''],
      start_date: ['', Validators.required],
      start_time: [''],
      end_date: ['', Validators.required],
      end_time: [''],
      activation_starts_at: ['', Validators.required],
      activation_ends_at: ['', Validators.required],
      emails_source_message: [''],
      // ga_code: [''],
      registration_url: ['', [Validators.required, Validators.pattern(this.constants.urlRegex)]],
      website_url: ['', [Validators.required, Validators.pattern(this.constants.urlRegex)]],
      google_utm_params: [''],
      register_average_price: [''],
      // report_analysis: [''],
      hashtag: [''],
      facebook: ['', Validators.pattern(this.constants.urlRegex)],
      twitter: ['', Validators.pattern(this.constants.urlRegex)],
      linkedin: ['', Validators.pattern(this.constants.urlRegex)],
      instagram: ['', Validators.pattern(this.constants.urlRegex)],
      description: [''],
      created_by: [this.currentUser.id, [Validators.required]],
      // planner_id: [''],
      dates: this.formBuilder.array([]),
      socials: this.formBuilder.array([]),
      fonts: this.formBuilder.array([this.addFontsFormGroup()]),
      custom_1: [''],
      custom_2: [''],
      custom_3: [''],
      custom_4: [''],
      custom_5: [''],
      custom_6: [''],
      custom_7: [''],
      custom_8: [''],
      custom_9: [''],
      custom_10: [''],
      exclude_emails: [''],
      exclude_ips: [''],
      notes: [''],
      sessions_can_be_updated_influencer: [false]
    });

    this.viewProjectForm.patchValue({client_id: this.project?.client_id});

    this.viewProjectForm.get('start_date').valueChanges.subscribe(value => {
      this.max =  new Date(value);
    });
    // this.route.params.subscribe (
    //     (params) => {
    //       this.step = { title: 'Project' };
    //     }
    // );

  }

  ngAfterViewInit() {
    this.getAssets();
  }

  addSocialsFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      social_label: [''],
      social_value: ['']
    });
  }

  addDatesFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      important_text: [],
      important_date: []
    });
  }

  addFontsFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      name: [],
    });
  }


  addDatesButtonClick(): void {
    this.dateFormGroup.push(this.addDatesFormGroup());
  }

  addSocialsButtonClick(): void {
    this.socialFormGroup.push(this.addSocialsFormGroup());
  }

  removeDatesButtonClick(index: number): void {
    this.dateFormGroup.removeAt(index);
  }

  removeSocialsButtonClick(index: number): void {
    this.socialFormGroup.removeAt(index);
  }

  onFontsLinkChange(e) {
    const {value} = e.target;
    this.fontsFormGroup.reset();
    this.fontsFormGroup.push(
      this.formBuilder.group({
        name: [value],
      })
    );
  }

  loadAllPlanners(search = '', page = 0, perPage = 10, orderBy= {'column': 'name', 'dir': 'asc'}, fields = ['id', 'name']) {
    this.clientStore.dispatch(ClientsList({
      params: {
        options: JSON.stringify({includePackage: true, includePagination: true, orderBy, fields}),
        page: page + 1,
        perPage,
        search
      }
    }));
  }

  updateProject() {
    Object.keys(this.viewProjectForm.controls).forEach(key => {
      const controlErrors = this.viewProjectForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
    this.submitted = true;
    if (this.viewProjectForm.invalid) {
      setTimeout(() => {
        this.scrollToField();
      }, 500);
      return;
    }

    if (this.imageSignedUrl && this.imageFile) {
      this.fileUploadStore.dispatch(UploadToSignedUrl({files: [{url: this.imageSignedUrl, fileData: this.imageFile}]}));
    } else {
      this.updateProjectData();
    }
  }

  updateProjectData() {
    const params: any = {
      ...this.viewProjectForm.value,
      stats_exclude: {
        emails: this.viewProjectForm.value.exclude_emails.split(','),
        ips: this.viewProjectForm.value.exclude_ips.split(','),
      }
    };

    if (this.viewProjectForm.get('timezone').value) {
      params.timezone = this.viewProjectForm.get('timezone').value.nameValue;
    }

      let fromDate: any = `${(params.start_date ? moment(params.start_date).format('MM/DD/YYYY') : '') || ''} ${params.start_time || ''}`;
    let toDate: any = `${(params.end_date ? moment(params.end_date).format('MM/DD/YYYY') : '') || ''} ${params.end_time || ''}`;
    if (fromDate.trim()) {
      // fromDate = this.tzDatePipe.toUTCMomentObject(moment(fromDate.trim(), `MM/DD/YYYY${params.start_time ? ' h:m A' : ''}`));
      fromDate = moment(fromDate.trim(), `MM/DD/YYYY${params.start_time ? ' hh:mm A' : ''}`);
      params.start_time = params.start_time ? fromDate.format('hh:mm A') : '';
      params.start_date = params.start_date ? fromDate.format('MM/DD/YYYY') : '';
    } else {
      fromDate = null;
      params.start_date = '';
    }
    if (toDate.trim()) {
      // toDate = this.tzDatePipe.toUTCMomentObject(moment(toDate.trim(), `MM/DD/YYYY${params.end_time ? ' h:m A' : ''}`));
      toDate = moment(toDate.trim(), `MM/DD/YYYY${params.end_time ? ' hh:mm A' : ''}`);
      params.end_time = params.end_time ? toDate.format('hh:mm A') : '';
      params.end_date = params.end_date ? toDate.format('MM/DD/YYYY') : '';
    } else {
      toDate = null;
      params.end_date = '';
    }
    if(params.activation_starts_at) {
      params.activation_starts_at = params.activation_starts_at ? moment(params.activation_starts_at).format('MM/DD/YYYY') : '';
    }
    if(params.activation_ends_at) {
      params.activation_ends_at = params.activation_ends_at ? moment(params.activation_ends_at).format('MM/DD/YYYY') : '';
    }

    let htmlBody: any = this.getHtml();
    if (htmlBody === '') {
      params['report_analysis'] = null;
    } else {
      htmlBody = htmlBody.replaceAll(/data-id="[^"]*"/g, '');
      htmlBody = htmlBody.replaceAll(' fp-active', '');
      htmlBody = htmlBody.replaceAll('fp-active', '');
      params['report_analysis'] = htmlBody;
    }

    delete params.exclude_emails;
    delete params.exclude_ips;
    delete params.stats_exclude_hash;
    this.projectStore.dispatch(ProjectUpdate({project: params, projectId: this.id}));
  }

  // START: Function used to fetch industries list.
  public getIndustries() {
    this.projectStore.dispatch(ProjectIndustries());
  }

  uploadLogo(fileInput) {
    fileInput.click();
  }

  timezoneSelect(event: any) {
    this.viewProjectForm.patchValue({timezone: event.value});
  }

  onProjectImageChanged(event) {
    if (event.target.files && event.target.files.length) {
      this.imageFile = event.target.files[0];
      this.fileUploadStore.dispatch(SignedUrl({fileName: this.imageFile.name, contentType: this.imageFile.type}));
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(this.imageFile);
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      }).then(data => {
        this.imageBinary = data;
      });
    }
  }

  private loadProject(id) {
    this.projectStore.dispatch(ProjectGet({projectId: id}));
  }

  updateValue(variable, textFor) {
    const value = this.viewProjectForm.get(this.variablesFor).value;
    const varValue = variable.key;
    this.viewProjectForm.controls[this.variablesFor].setValue(`${value} ${varValue}`);
  }

  scrollToField() {
    const invalid = this.elRef.nativeElement.querySelector('.invalid-text');
    if (invalid) {
      this.toastr.warning('Some fields have error, please review', 'warning');
      window.scrollBy({
        top: invalid.getBoundingClientRect().top - 150,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  enableEditor(css: any) {
    const that = this;
    this.templateSectionService.addCustomVariables(FroalaEditor, this.variables);
    const editorOptions = {
      key: environment.froalaEditorKey,
      iframe: true,
      iframeStyleFiles: css,
      attribution: false,
      charCounterCount: false,
      htmlUntouched: true,
      imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
      imageUploadURL: environment.apiUrl + '/api/v1/file',
      fileUploadURL: environment.apiUrl + '/api/v1/file',
      height: 'auto',
      heightMax: 'auto',
      heightMin: 400,
      codeMirror: getCodeMirror(),
      codeMirrorOptions: {
        indentWithTabs: true,
        lineNumbers: true,
        lineWrapping: true,
        mode: 'text/html',
        tabMode: 'indent',
        tabSize: 2
      },
      requestHeaders: {
        'Authorization': `Bearer ${this.token}`
      },
      events: {
        'initialized': function () {
          that.editorLoaded = true;
          that.changeContent('section');
        },
        'image.uploaded': function (response) {
          const jsonResponse = JSON.parse(response);
          this.image.insert(jsonResponse.data.url, false, null, this.image.get(), response);
          return false;
        },
        'file.uploaded': function (response) {
          const jsonResponse = JSON.parse(response);
          this.file.insert(jsonResponse.data.url, jsonResponse.data.file, null);
          return false;
        },
      },
      htmlAllowedEmptyTags: ['textarea', 'a', 'iframe', 'object', 'video', 'style', 'script', '.fa', '.fr-emoticon', '.fr-inner', 'path', 'line', 'hr', 'i', 'span', 'div'],
      linkInsertButtons: ['linkBack', '|', 'linkList', 'linkUrlVariables', 'linkTextVariables'],
      pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'cryptoJSPlugin', 'draggable', 'emoticons', 'entities',
        'file', 'filesManager', 'fontAwesome', 'fontFamily', 'fontSize', 'forms', 'fullscreen', 'help', 'image', 'imageManager', 'imageVariable',
        'inlineClass', 'inlineStyle', 'lineBreaker', 'lineHeight', 'link', 'lists', 'markdown', 'paragraphFormat', 'paragraphStyle',
        'print', 'quickInsert', 'quote', 'save', 'specialCharacters', 'table', 'url', 'video', 'wordPaste', 'html'],
      imageEditButtons: ['imageReplace', 'imageVariableButton', 'imageAlign', 'imageCaption', 'imageRemove', 'imageLink', 'linkOpen',
        'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize'],
      toolbarButtons: ['bold', 'italic', 'textColor', 'backgroundColor', 'paragraphFormat', 'align', 'emoticons', 'insertLink',
        'insertImage', 'undo', 'redo', '-', 'underline', 'subscript', 'superscript', 'fontSize', 'fontFamily', 'insertTable', 'formatOL', 'formatUL',
        'lineHeight', 'insertVideo', 'fontAwesome', 'inlineClass', 'inlineStyle', 'insertFile', 'html']
    };
    this.editor = new FroalaEditor('#analysis_editor', editorOptions);
    if (Object.keys(this.editor).length > 0) {
      this.addCssToFroala();
    } else {
      setTimeout(() => {
        this.editor = new FroalaEditor('#analysis_editor', editorOptions);
      }, 1000);
    }
  }

  changeContent(type = '') {
    this.editor.html.set('');
  }

  addCssToFroala() {
    const css = this.editor.$iframe[0].contentWindow.document.createElement('style');
    css.innerHTML = this.templateSectionService.getFroalaCss();
    this.editor.$iframe[0].contentWindow.document.head.appendChild(css);
    this.isLoadEditor = true;
  }

  getAssets() {
    this.templateSectionStore.dispatch(TemplateSectionGetAssets({params: {html_mode: 'editor'}}));
  }

  getHtml() {
    return this.editor.html.get(true);
  }

  patchData(htmlBody) {
    if (this.editorLoaded) {
      htmlBody ? this.editor.html.set(htmlBody) : this.changeContent();
    } else {
      setTimeout(() => {
        this.patchData(htmlBody);
      }, 1000);
    }
  }

  clientSelected(event: any) {
    this.viewProjectForm.controls['google_utm_params'].setValue(null);
    if (event.value) {
      this.viewProjectForm.patchValue({ client_id: event.value });
      this.selectedClient = this.clients.find(client => client.id === event.value);
      if (this.selectedClient?.require_google_utm_for_cta_links) {
        //this.viewProjectForm.controls['google_utm_params'].setValue(`utm_source=snoball&utm_medium=referral&utm_term=referral-marketing`);
      }
    } else {
      this.loadAllPlanners();
    }
  }

  loadClient(clientId: number) {
    this.clientStore.dispatch(ClientGet({clientId}));
  }

  handleSearchValue(searchTerm) {
    this.loader$ = null;
    this.loadAllPlanners(searchTerm.term);
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  onAddVariablesForClick(variablesFor) {
    this.variablesFor = variablesFor;
  }
}
