import { Component, Input, OnChanges, SimpleChanges, ViewChild, ElementRef, OnInit, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'email-template-editor',
  templateUrl: './email-template-editor.component.html',
  styleUrls: ['./email-template-editor.component.scss']
})
export class EmailTemplateEditorComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() template: any;
  @Input() variables: any;
  @Output() EditorStateChanged = new EventEmitter();
  @ViewChild('editorIframe') iframe: ElementRef | undefined;
  currentUser: any = {};

  iframeSrc: string = '';
  editorUrl: string = '';

  constructor() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.editorUrl = `${environment.apiUrl}/api/v1/editor/email/template`;
  }

  ngOnInit(): void {
    window.addEventListener("message", this.handleEditorMessages.bind(this));
  }

  ngAfterViewInit(): void {
    if (this.iframe) {
      this.iframeSrc = `${this.editorUrl}?topPanel=false`;
      this.iframe.nativeElement.src = this.iframeSrc;

      const variables = Object.values(this.variables).map(x => {
        const variable = x as { key: string; title: string; valueType: string };
        return {
          key: variable.key,
          name: variable.title,
          valueType: variable.valueType,
        };
      });

      this.iframe.nativeElement.onload = () => {
        if (!!this.template) {
          this.iframe.nativeElement.contentWindow.postMessage({
              action: 'init-data',
              origin: 'sb-email-template',
              template_json_body: this.template.body_json,
              variables: variables,
              accessToken: this.currentUser.token,
          }, '*');
        } else {
          this.iframe.nativeElement.contentWindow.postMessage({
              action: 'init-data',
              origin: 'sb-email-template',
              template_json_body: null,
              variables: variables,
              accessToken: this.currentUser.token,
          }, '*');
        }
      };
    }
    /*if (this.iframe && this.template) {
      if (this.iframeSrc === '') {
        this.iframeSrc = `${this.editorUrl}?campaignId=${this.template.campaign_id}&influencerTypeId=${this.template.influencer_type_id}&token=${this.currentUser.token}&isPreview=true&topPanel=false`;
      }
      this.iframe.nativeElement.src = this.iframeSrc;

      this.iframe.nativeElement.onload = () => {
        //this.adjustIframeHeight();
      };
    }*/
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['template'] && this.template && this.iframe) {
      this.iframeSrc = `${this.editorUrl}?campaignId=${this.template.campaign_id}&influencerTypeId=${this.template.influencer_type_id}&token=${this.currentUser.token}&isPreview=true&topPanel=false`;
      this.iframe.nativeElement.src = this.iframeSrc;
    }
  }

  /*adjustIframeHeight(): void {
    if (this.iframe) {
      const iframeWindow = this.iframe.nativeElement.contentWindow;
      if (iframeWindow && iframeWindow.document.body.scrollHeight) {
        this.iframe.nativeElement.style.height = iframeWindow.document.body.scrollHeight + 'px';
      }
    }
  }*/

  handleEditorMessages(event: MessageEvent): void {
    if (event.data.origin  === "email-editor") {
      if (event.data.action === "save") {
        this.EditorStateChanged.emit({
          body: event.data.body,
          body_json: event.data.body_json,
          action: 'save'
        });
      }

      if (event.data.action === "close") {
        this.EditorStateChanged.emit({
          action: 'close'
        });
      }
      /*const existingIframe = document.getElementById('editorIframe');
      if (existingIframe) {
          existingIframe.remove();
      }*/
    }
  }

}
