<div class="container-fluid mobile-view pt-3 new-styles">
  <div class="rightBody">
    <div class="card card-box mb-5">
      <div class="card-header py-3">
        <div class="card-header--title font-size-lg">
          Manage Campaigns
        </div>
        <div class="card-header--actions">
          <button class="btn btn-sm btn-primary pull-right" type="button"
                  routerLink="/{{routes.createCampaign({projectId: projectId})}}" role="button">
            <span class="btn-wrapper--icon"><i class="fa fa-plus"></i></span>
            <span class="btn-wrapper--label">Start a Campaign</span>
          </button>
        </div>
      </div>
      <div class="row py-3 m-0">
        <div class="col-md-4 d-flex align-items-center justify-content-start">
          <div class="client-dropdown">
            <app-creatable-select
              [hideLabel]="true" [isCreatable]="false" [onlyBottomBorder]="false"
              [placeholder]="'Select influencer type'" [selectOptions]="influencerTypes"
              (optionSelected)="influencerType = $event ? $event.value : 'all';
             filterCampaignsByInfluencerType($event ? $event.value : 'all')"></app-creatable-select>
          </div>
        </div>
        <div class="col-md-8 d-flex align-items-center justify-content-end">
          <a class="filters" [ngClass]="{'active-status': selectedStatus === 'all'}" (click)="filterCampaignsByStatus('all')">
            All
          </a>
          <a class="filters" [ngClass]="{'active-status': selectedStatus === 'launched'}" (click)="filterCampaignsByStatus('launched')">
            Active
          </a>
          <a class="filter-last" [ngClass]="{'active-status': selectedStatus === 'draft'}" (click)="filterCampaignsByStatus('draft')">
            Draft
          </a>
          <button type="button" [ngClass]="{'active-btn': viewType === 'card', 'inactive-btn': viewType !== 'card'}" class="btn btn-default btn-sm list-button ml-4" (click)="changeViewType('card')">
            <i class="fa fa-th-large mr-1"></i>
            Card
          </button>
          <button type="button" [ngClass]="{'active-btn': viewType === 'list', 'inactive-btn': viewType !== 'list'}" class="btn btn-default btn-sm card-button" (click)="changeViewType('list')">
            <i class="fa fa-th-list mr-1"></i>
            List
          </button>
        </div>
      </div>
      <div class="sticky-dropdown-custom-table p-3" [ngClass]="{'card-view-table': viewType === 'card'}">
        <table width="100%" class="sb-table table dataTable no-footer nowrap" datatable
               [dtTrigger]="dtTrigger"
               [dtOptions]="dtOptions"
               id="dt-table">
          <thead>
          <tr *ngIf="viewType === 'list'">
            <th>CAMPAIGN NAME</th>
            <!--            <th>TEMPLATE THUMBNAIL</th>-->
            <th class="no-sort">INFLUENCERS</th>
            <!--            <th>SEGMENTS</th>-->
            <!--            <th>INFLUENCER TYPE</th>-->
            <!--            <th>IMPRESSIONS</th>-->
            <!--            <th>SHARES</th>-->
            <th class="no-sort">CONVERSIONS</th>
            <th class="no-sort">Social Shares</th>
            <th class="no-sort">OPEN RATE</th>
            <th>STATUS</th>
            <th class="no-sort">EMAIL SUBJECT</th>
            <th class="no-sort"></th>
          </tr>
          <tr *ngIf="viewType === 'card'">
            <th>STATUS</th>
            <th>CAMPAIGN NAME</th>
            <th>EMAIL SUBJECT</th>
            <th class="custom-td">INFLUENCERS</th>
            <th class="custom-td">CONVERSIONS</th>
            <th class="custom-td">OPEN RATE</th>
            <th></th>
          </tr>
          </thead>
          <tbody *ngIf="campaigns?.length > 0">
          <ng-container *ngIf="viewType === 'list'">
            <tr
              *ngFor="let campaign of campaigns">
              <td class="font-weight-bold cursor-pointer campaign-name"
                  [routerLink]="'/'+routes.addInfluencers({projectId: projectId})"
                  [queryParams]="{campId: campaign.id}">
                {{campaign.name}}
                <ng-container *ngIf="campaign.created_by || campaign.updated_by">
                  <app-info-popover container="body" info="{{getCampaignUpdateInfo(campaign)}}" ></app-info-popover>
                </ng-container>
                <!--                <small class="d-block" *ngIf="campaign.launched_at">{{formatDate(campaign.launched_at)}}</small>-->
                <div class="d-block" >
                  <small>{{campaign.code}}</small>
                  <small *ngIf="campaign.launched_at && campaign.status == 'launched'">
                    - Launched {{campaign.launch_user ? 'by' : 'at'}} {{campaign.launch_user?.first_name | titlecase}} {{campaign.launch_user?.last_name | titlecase}}
                    - {{smartDateFormat(campaign?.launched_at)}}
                    <app-info-popover *ngIf="campaign.timezone || project?.timezone" container="body"
                                      info="{{getDateTimeBasedOnCampaign(campaign, project)}}"></app-info-popover>
                  </small>
                </div>
              </td>
              <!--            <td>-->
              <!--              <img class="image-thumbnail" [src]="campaign.template?.thumbnail" alt="Template thumbnail">-->
              <!--            </td>-->
              <td class="text-left" [routerLink]="'/'+routes.addInfluencers({projectId: projectId})" [queryParams]="{campId: campaign.id}">
                {{campaign.influencers_count === 0 ? '-' : campaign.influencers_count}}
                <app-info-popover *ngIf="(campaign.influencers_count_possible_total_audience <= campaign.influencers_count && campaign.influencers_count_possible_receive_email <= 0) || campaign.status !== 'launched'" container="body" info="{{campaign.influencers_count}} Influencer were part of this campaign"></app-info-popover>
                <app-re-launched-popover *ngIf="(campaign.influencers_count_possible_total_audience > campaign.influencers_count || campaign.influencers_count_possible_receive_email > 0) && campaign.status === 'launched'" container="body" newInfluencerCount="{{campaign.influencers_count_possible_total_audience - campaign.influencers_count}}" campaignEmailInfluencerCount="{{campaign.influencers_count_possible_receive_email}}" [campaignId]="campaign.id" (IsClicked)="reLaunch($event)"></app-re-launched-popover>
              </td>
              <!--            <td class="text-left" [routerLink]="'/'+routes.addInfluencers({projectId: projectId})"-->
              <!--                [queryParams]="{campId: campaign.id}"> {{campaign.segments[0] ? campaign.segments[0].name : ''}} </td>-->

              <!--            <td class="text-left" [routerLink]="'/'+routes.addInfluencers({projectId: projectId})"-->
              <!--                [queryParams]="{campId: campaign.id}"> {{campaign.segments[0] ? (campaign.segments[0].influencer_type ? campaign.segments[0].influencer_type.name : '') : ''}} </td>-->
              <!--            <td class="text-left">{{campaign.visits}}</td>-->
              <!--            <td class="text-left">{{campaign.clicks}}</td>-->
              <td class="text-left">{{campaign.referrals === 0 ? '-': campaign.referrals}}</td>
              <td class="text-left">{{campaign.social_shares === 0 ? '-': campaign.social_shares}}</td>
              <td class="text-left">
                {{getEmailSendProgress(campaign)}}
                <app-info-popover container="body" info="{{getEmailSendProgressInfo(campaign)}}" ></app-info-popover>
              </td>
              <td>
                <app-campaign-status [campaign]="campaign" [projectId]="projectId" [currentUser]="currentUser" [subscribeSocket]="false"></app-campaign-status>
                <!--<ng-container *ngIf="campaign.progress">
                  <span class="badge text-primary">
                    Launching Campaign {{campaign.progress}}%
                  </span>
                </ng-container>
                <ng-container *ngIf="!campaign.progress || campaign.progress < 0">
                  <span *ngIf="campaign.status==='launched'" class="badge">
                    <span class="fa fa-check-circle-o check"></span>
                    <a
                      [routerLink]="'/'+routes.statisticsForCampaign({projectId: projectId}, {campaignId: campaign.id})"
                      [queryParams]="{campaignId: campaign.id}"> View Report</a>
                  </span>
                  <span *ngIf="campaign.status==='launch_queued'" class="badge text-primary">
                    Queued
                  </span>
                  <span *ngIf="campaign.status==='draft'" class="badge text-primary"
                        [routerLink]="'/'+routes.addInfluencers({projectId: projectId})"
                        [queryParams]="{campId: campaign.id}">
                    Draft
                  </span>
                  <span *ngIf="campaign.status==='launch_queued'" class="badge text-primary">
                    Queued
                  </span>
                  <span *ngIf="campaign.status==='launch_scheduled'" class="badge text-primary">
                    Scheduled
                  </span>
                  <span *ngIf="campaign.status==='launch_failed'" class="badge text-primary">
                    failed
                    <app-info-popover
                      info="{{ campaign.status_message }}">
                  </app-info-popover>
                  </span>
                </ng-container>-->
              </td>
              <td class="text-left email-subject">{{campaign.email_subject}}</td>
              <!--              <td>{{formatMoment(campaign.updated_at) | tzDate : false : 'DD-MM-YYYY hh:mm a'}}</td>-->
              <td>
                <button class="btn-icon float-right" mat-icon-button [matMenuTriggerFor]="menu"
                        aria-label="Example icon-button with a menu">
                  <span class="fa fa-ellipsis-v"></span>
                </button>
                <mat-menu #menu="matMenu" class="campaign-popover">
                  <a class="dropdown-item" (click)="openRenameModal(campaign)">
                    <i class="fa fa-pencil mr-2"></i>Rename
                  </a>
                  <a class="dropdown-item" [routerLink]="'/'+routes.addInfluencers({projectId: projectId})"
                     [queryParams]="{campId: campaign.id}">
                    <i class="fa fa-list mr-2" aria-hidden="true"></i>Manage Influencers</a>
                  <a class="dropdown-item" *ngIf="campaign.segments.length > 0 && campaign.landing_page_status !== 'skipped'" (click)="onEditCampaignTemplate(campaign)">
                    <i class="fa fa-paint-brush mr-2" aria-hidden="true"></i>
                    {{campaign.template ? 'Edit' : 'Add'}} Landing Page</a>
                  <a class="dropdown-item" *ngIf="campaign.segments?.length > 0 && ((campaign.template && campaign.landing_page_status !== 'skipped') || campaign.landing_page_status === 'skipped' )" (click)="onEditCampaignShare(campaign)">
                    <i class="fa fa-share mr-2" aria-hidden="true"></i>Edit Share</a>
                  <a class="dropdown-item" *ngIf="campaign.segments?.length > 0 && ((campaign.template && campaign.landing_page_status !== 'skipped') || campaign.landing_page_status === 'skipped' )" (click)="onEditCampaignPreview(campaign)">
                    <i class="fa fa-envelope mr-2" aria-hidden="true"></i>Edit Email</a>
                  <a class="dropdown-item" (click)="copyCampaign(campaign.id)">
                    <i class="fa fa-clipboard mr-2"></i>Duplicate</a>
                  <a class="dropdown-item" *ngIf="campaign.status!=='draft'"
                     [routerLink]="'/'+routes.statisticsForCampaign({projectId: projectId}, {campaignId: campaign.id})"
                     [queryParams]="{campaignId: campaign.id}"><i class="fa fa-eye mr-2"></i>View report</a>
                  <a class="dropdown-item" (click)="deleteCampaign(campaign.id)">
                    <i class="fa fa-trash mr-2"></i>Delete</a>
                  <a class="dropdown-item" *ngIf="['launch_queued', 'launch_scheduled', 'in-progress', 'launching', 'launch_in_progress'].includes(campaign.status)" (click)="cancelLaunch(campaign.id)">
                    <i class="fa fa-times-circle mr-2" aria-hidden="true"></i>Cancel Launch</a>
                  <a class="dropdown-item" *ngIf="['launched', 'launch_failed'].includes(campaign.status)" (click)="reLaunch(campaign.id)">
                    <i class="fa fa-rocket mr-2" aria-hidden="true"></i>Re-Launch</a>
                  <a class="dropdown-item" *ngIf="['draft', 'launch_failed'].includes(campaign.status) && campaign.email_from_name && campaign.email_reply_to && campaign.email_subject"
                     (click)="launchCampaign(campaign.id)">
                    <i class="fa fa-rocket mr-2" aria-hidden="true"></i>Launch Page</a>
                  <a class="dropdown-item" [href]="campaign.share_guide_url" target="_blank">
                    <i class="fa fa-book mr-2" aria-hidden="true"></i>Setup Guide</a>
                </mat-menu>
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="viewType === 'card'">
            <tr
              *ngFor="let campaign of campaigns">
              <td class="w-100 popup-button-section">
                <button class="btn-icon float-right" mat-icon-button [matMenuTriggerFor]="menu"
                        aria-label="Example icon-button with a menu">
                  <span class="fa fa-ellipsis-v"></span>
                </button>
                <mat-menu #menu="matMenu" class="campaign-popover">
                  <a class="dropdown-item" (click)="openRenameModal(campaign)">
                    <i class="fa fa-pencil mr-2"></i>Rename
                  </a>
                  <a class="dropdown-item" [routerLink]="'/'+routes.addInfluencers({projectId: projectId})" [queryParams]="{campId: campaign.id}">
                    <i class="fa fa-list mr-2" aria-hidden="true"></i>Manage Influencers</a>
                  <a class="dropdown-item" *ngIf="campaign.segments.length > 0 && campaign.landing_page_status !== 'skipped'" (click)="onEditCampaignTemplate(campaign)">
                    <i class="fa fa-paint-brush mr-2" aria-hidden="true"></i>{{campaign.template ? 'Edit' : 'Add'}} Landing Page</a>
                  <a class="dropdown-item" *ngIf="campaign.segments?.length > 0 && ((campaign.template && campaign.landing_page_status !== 'skipped') || campaign.landing_page_status === 'skipped' )" (click)="onEditCampaignShare(campaign)">
                    <i class="fa fa-share mr-2" aria-hidden="true"></i>Edit Share</a>
                  <a class="dropdown-item" *ngIf="campaign.segments?.length > 0 && ((campaign.template && campaign.landing_page_status !== 'skipped') || campaign.landing_page_status === 'skipped' )" (click)="onEditCampaignPreview(campaign)">
                    <i class="fa fa-envelope mr-2" aria-hidden="true"></i>Edit Email</a>
                  <a class="dropdown-item" (click)="copyCampaign(campaign.id)">
                    <i class="fa fa-clipboard mr-2"></i>Duplicate</a>
                  <a class="dropdown-item" *ngIf="campaign.status!=='draft'"
                     [routerLink]="'/'+routes.statisticsForCampaign({projectId: projectId}, {campaignId: campaign.id})"
                     [queryParams]="{campaignId: campaign.id}">
                    <i class="fa fa-eye mr-2"></i>View report</a>
                  <a class="dropdown-item" (click)="deleteCampaign(campaign.id)">
                    <i class="fa fa-trash mr-2"></i>Delete</a>
                  <a class="dropdown-item" *ngIf="['launch_queued', 'launch_scheduled', 'in-progress', 'launching'].includes(campaign.status)" (click)="cancelLaunch(campaign.id)">
                    <i class="fa fa-times-circle mr-2" aria-hidden="true"></i>Cancel Launch</a>
                  <a class="dropdown-item" *ngIf="['launched', 'launch_failed'].includes(campaign.status)" (click)="reLaunch(campaign.id)">
                    <i class="fa fa-rocket mr-2" aria-hidden="true"></i>Re-Launch</a>
                  <a class="dropdown-item" *ngIf="['draft', 'launch_failed'].includes(campaign.status) && campaign.email_from_name && campaign.email_reply_to && campaign.email_subject"
                     (click)="launchCampaign(campaign.id)">
                    <i class="fa fa-rocket mr-2" aria-hidden="true"></i>Launch Page</a>
                  <a class="dropdown-item" [href]="campaign.share_guide_url" target="_blank">
                    <i class="fa fa-book mr-2" aria-hidden="true"></i>Setup Guide</a>
                </mat-menu>
              </td>
              <td class="no-label custom-margin w-100">
                <app-campaign-status [layoutType]="'card'"  [campaign]="campaign" [projectId]="projectId" [currentUser]="currentUser" [subscribeSocket]="false"></app-campaign-status>
                <div class="no-label font-weight-bold cursor-pointer w-100 campaign-name" [routerLink]="'/'+routes.addInfluencers({projectId: projectId})"
                     [queryParams]="{campId: campaign.id}">
                  {{campaign.name}}
                  <ng-container *ngIf="campaign.created_by || campaign.updated_by">
                    <app-info-popover container="body" info="{{getCampaignUpdateInfo(campaign)}}" ></app-info-popover>
                  </ng-container>
                  <!--                  <small *ngIf="campaign.launched_at">{{formatDate(campaign.launched_at)}}</small>-->
                  <div class="card-date-section">
                    <small>{{campaign.code}}</small>
                    <small *ngIf="campaign.launched_at && campaign.status == 'launched'">
                      - Launched {{campaign.launch_user ? 'by' : 'at'}} {{campaign.launch_user?.first_name | titlecase}} {{campaign.launch_user?.last_name | titlecase}} - {{formatMoment(campaign?.launched_at) | tzDate : false : 'DD MMM, YYYY h:mm A'}}
                      <app-info-popover *ngIf="campaign.timezone || project?.timezone" container="body" info="{{getDateTimeBasedOnCampaign(campaign)}}" ></app-info-popover>
                    </small>
                  </div>
                </div>
              </td>
              <hr class="w-100" *ngIf="campaign.email_subject">
              <td *ngIf="campaign.email_subject" class="email-subject w-100 text-center">{{campaign.email_subject}}</td>
              <td class="text-left custom-td" [routerLink]="'/'+routes.addInfluencers({projectId: projectId})"
                  [queryParams]="{campId: campaign.id}" data-label="INFLUENCERS">
                  <span>
                    {{campaign.influencers_count}}
                  </span>
              </td>
              <td class="text-left custom-td" data-label="CONVERSIONS">
                <span>
                  {{campaign.referrals}}
                </span>
              </td>
              <td class="text-left custom-td" title="{{getEmailSendProgressInfo(campaign)}}" data-label="OPEN RATE">
                <span>{{getEmailSendProgress(campaign)}}</span></td>
              <!--              <td class="d-none">{{formatInsertTime(campaign.updated_at)}}</td>-->
              <!--              <td class="d-none">{{formatMoment(campaign.updated_at) | tzDate : false : 'DD-MM-YYYY hh:mm a'}}</td>-->
              <td class="d-none"></td>
            </tr>
          </ng-container>
          </tbody>
          <tbody *ngIf="campaigns?.length <= 0">
          <tr class="no-data-available-custom">
            <td colspan="6">No data available in table</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
